const orderTypeEnum = Object.freeze({
    1: "purchase",
    2: "print",
    3: "dye",
    4: "shrinkage"
});

const getOrderTypeName = (orderTypeId) => {
    return orderTypeEnum[orderTypeId];
}
module.exports = { getOrderTypeName };