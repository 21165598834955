import { React, useEffect, useState, useRef } from 'react'
import PDFGenerator from '../../pdfGenerator/pdfGenerator'
import MDButton from '../../../components/MDButton'
import MDTypography from '../../../components/MDTypography'
import MDInput from '../../../components/MDInput'
import EditIcon from '@mui/icons-material/Edit'
import AddNewFabricToVendorMapping from '../../dashboard/vendorDetails/fabricToVendorMapping/AddNewFabricToVendorMapping'
import {
  Modal,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Snackbar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip
} from '@mui/material'
import InventoryDialog from './InventoryDialog'
import fabricVendorMappingSchema from '../../dashboard/vendorDetails/fabricToVendorMapping/fabricVendorMappingUtil'
import Tooltip from '@mui/material/Tooltip'
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import Check from '@mui/icons-material/Check'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import MDBox from '../../../components/MDBox'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import MDAlert from '../../../components/MDAlert'
import Cookies from 'universal-cookie'
import { createTheme } from '@mui/material/styles'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  height: '35rem'
}

function PurchaseOrderCreate() {
  const cookies = new Cookies()
  const [openModal, setOpenModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [buffer, setBuffer] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState([])
  const [vendorData, setVendorData] = useState([])
  const [purchaseOrderMetaData, setPurchaseOrderMetaData] = useState([])
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [vendors, setVendors] = useState([])
  const [selectedVendor, setSelectedVendor] = useState({})
  const [orderTypes, setOrderTypes] = useState([])
  const [selectedOrderType, setSelectedOrderType] = useState(null)
  const [fabricIdMapping, setFabricIdMapping] = useState(null)
  const [purchaseOrderReqDataIdObj, setPurchaseOrderReqDataIdObj] = useState({})
  const [orderTypePoObj, setOrderTypePoObj] = useState({})
  const [editingRowId, setEditingRowId] = useState(null)
  const [editedPurchaseRate, setEditedPurchaseRate] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [disablePoFlow, setDisablePoFlow] = useState(true);
  const [newFabricVendorMappingAdded, setNewFabricVendorMappingAdded] = useState(0);
  const [snackBarMsg, setSnackBarMsg] = useState('')

  const [steps, setSteps] = useState([
    'Fill purchase order details',
    'Select Forecast',
    'Edit and submit'
  ])

  const [inventoryDailogOpen, setIventoryDialogOpen] = useState(false);
  const [inventoryDialogEditedRow, setInventoryDialogEditedRow] = useState(null);
  const [inventoryDailogItems, setInventoryDialogItems] = useState([]);

  const handleInventoryDialogOpen = () => {
    setIventoryDialogOpen(false);
  };
  const [selectedValue, setSelectedValue] = useState('');

  const handleDelete = (index, orderTypePoObj, orderTypePoObjRowKey, setOrderTypePoObj) => {
    delete orderTypePoObj[orderTypePoObjRowKey]?.fabricWiseData?.[index]?.outwardQuantity
    setOrderTypePoObj({ ...orderTypePoObj })
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const updatePurchaseOrderRate = async (rate, returnFabricId, currentVendor = null,) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/update/fabricRate`
    let fabricMasterId = undefined;
    let vendor = currentVendor || selectedVendor
    for (let i = 0; i < vendor.fabricIds.length; i++) {
      if (vendor.fabricIds[i].fabricInternalId === returnFabricId)
        fabricMasterId = vendor.fabricIds[i].fabricMasterID
    }
    const data = {
      rate,
      vendorId: vendor.vendorMasterId,
      fabricId: fabricMasterId
    }
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'refresh-token': await cookies.get('refresh')
      },
      body: JSON.stringify(data)
    }
    const res = await fetch(url, requestOptions)
    if (res.ok) {
      setOrderTypes(await res.json())
    }
    if (!res.ok) {
      setError('Error in fetching Orders list')
    }
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleRateSaveClick = (setPoObj, returnFabricId, currentVendor = null) => {
    setPoObj(poObj => {
      for (let item in poObj) {
        if (poObj[item].returnFabricId === returnFabricId) {
          poObj[item].purchaseRate = parseInt(editedPurchaseRate)
          poObj[item].amount = poObj[item].purchaseRate * poObj[item].quantity
        }
      }
      return poObj
    })
    setFabricIdMapping(fabricIdMap => {
      fabricIdMap[currentVendor?.vendorMasterId][returnFabricId].purchaseRate = parseInt(editedPurchaseRate)
      return fabricIdMap
    })
    updatePurchaseOrderRate(editedPurchaseRate, returnFabricId, currentVendor)
    setEditedPurchaseRate(null)
    setEditingRowId(null)
  }

  const handleRateInputChange = e => {
    setEditedPurchaseRate(e.target.value)
  }

  const handlePurchaseOrderReqDataIdObj = purOrdReqIdObj => {
    setPurchaseOrderReqDataIdObj(purOrdReqIdObj)
  }

  const handleOpen = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
    setActiveStep(0)
  }

  const handleEditChange = (e, poObj, setPoObj, columnName, key) => {
    let editedRow = poObj[key]
    if (columnName === 'expectedDeliveryDate') editedRow[columnName] = e
    else if (columnName === 'quantity' && parseInt(e.target.value) < 1) {
      setAlertMessage('Quantity should be Positive')
      setAlertOpen(true)
    }
    else if (columnName === 'quantity' && editedRow?.orderType !== 'purchase' && editedRow?.maxOutwardQuantity !== undefined && parseFloat(editedRow?.maxOutwardQuantity) < parseInt(e?.target?.value)) {
      setAlertMessage(`cannot send more than ${editedRow?.maxOutwardQuantity} Qty to ${editedRow?.orderType} for fabricId ${editedRow?.returnFabricId}`)
      setAlertOpen(true)
    }
    else editedRow[columnName] = e.target.value
    if (columnName === 'quantity')
      editedRow['amount'] =
        parseInt(editedRow[columnName]) * editedRow['purchaseRate']
    if (columnName == 'quantity' && selectedOrderType?.orderType === 4 && editedRow?.fabricWiseData !== undefined && editedRow?.fabricWiseData !== '') {

      editedRow.fabricWiseData[0].outwardQuantity = e.target?.value;
    }
    let newPoObj = poObj
    newPoObj[key] = editedRow
    setPoObj({ ...newPoObj })
  }

  const fetchVendorList = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/vendorList`
    const requestOptions = {
      method: 'GET'
    }

    const res = await fetch(url, requestOptions)

    if (res.ok) {
      let response = await res.json()
      let vendorsObj = {}
      let fabricIdMap = {}
      for (let i = 0; i < response.length; i++) {
        const vendorName = response[i].vendorName
        const vendorInternalId = response[i].vendorInternalId
        const fabricInternalId = response[i].fabricInternalId
        const purchaseRate = response[i].purchaseRate
        const moq = response[i].moq
        const inHaryana = response[i].inHaryana
        const vendorMasterId = response[i].vendor_master_id
        const fabricMasterID = response[i].fabric_master_id
        if (vendorsObj[vendorInternalId]) {
          vendorsObj[vendorInternalId].fabricIds.push({
            fabricInternalId,
            fabricMasterID
          })
        } else {
          vendorsObj[vendorInternalId] = {
            fabricIds: [{ fabricInternalId, fabricMasterID }],
            vendorName: vendorName,
            vendorMasterId: vendorMasterId,
            inHaryana: inHaryana
          }
        }
        if (fabricIdMap[vendorMasterId] === undefined)
          fabricIdMap[vendorMasterId] = {};

        fabricIdMap[vendorMasterId][fabricInternalId] = { purchaseRate: purchaseRate, moq: moq }
      }
      let vendorsList = []
      for (let key in vendorsObj) {
        vendorsList.push({
          vendorInternalId: key,
          ...vendorsObj[key]
        })
      }
      setVendors(vendorsList)
      setFabricIdMapping(fabricIdMap)
    }
    if (!res.ok) {
      setError('Error in fetching Vendors list')
    }
  }

  const fetchOrderType = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/orderType`
    const requestOptions = {
      method: 'GET'
    }
    const res = await fetch(url, requestOptions)
    if (res.ok) {
      setOrderTypes(await res.json())
    }
    if (!res.ok) {
      setError('Error in fetching Orders list')
    }
  }

  const validatePoObjReqData = async poObj => {
    for (let key in poObj) {
      const poSingleObj = poObj[key]
      for (let _ in poSingleObj) {
        if (poSingleObj[_] === null || poSingleObj[_] === '') return false;
      }
    }
    return true
  }

  const createPurchaseOrder = async (purchaseOrder, orderType = null) => {
    setBuffer(true)
    const isReqDataClean = await validatePoObjReqData(purchaseOrder);
    if (isReqDataClean === false) {
      setError('Please fill all the values')
      setBuffer(false)
    } else {
      const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/order/orderRequest`
      let forecastReqBody = []
      if (orderType == 1 || orderType == 4) {
        for (let key in purchaseOrder) {
          forecastReqBody.push({
            forecastId: key,
            ...purchaseOrder[key]
          })
        }
      }
      else {
        for (let key in purchaseOrder) {
          forecastReqBody.push({
            ...purchaseOrder[key]
          })
        }
      }

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          vendorId: selectedVendor.vendorMasterId,
          inHaryana: selectedVendor.inHaryana,
          raisedBy: await cookies.get('id'),
          orderType: selectedOrderType.orderType,
          forecast: forecastReqBody
        }),
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow'
      }

      const res = await fetch(url, requestOptions)
      const result = await res.json()
      if (res.ok) {
        setError(null)
        setSuccess('Purchase order created successfully')
        setActiveStep(0)
        setOpenModal(false)
        const purchaseOrderData = result.map((po, SR_No) => {
          const Fabric_ID = po.returnFabricId
          const Product_Name = po.fabricName
          const color = po.color
          const quantity = po.quantity
          const rate = po.rate
          const amount = po.amount
          const igst = po?.igst
          const cgst = po?.cgst
          const sgst = po?.sgst
          SR_No += 1
          return {
            SR_No,
            Fabric_ID,
            Product_Name,
            color,
            quantity,
            rate,
            amount,
            igst,
            cgst,
            sgst
          }
        })
        const vendorInfo = result.map(vm => {
          const vendorName = vm.vendorName
          const vendorGst = vm.gst
          const vendorPhoneNo = vm.phone_number
          const vendorEmail = vm.email
          const vendorAddress = vm.address
          const vendorCity = vm.city
          const vendorPincode = vm.pincode
          const inHaryana = vm.in_haryana

          return {
            vendorName,
            inHaryana,
            vendorGst,
            vendorPhoneNo,
            vendorEmail,
            vendorAddress,
            vendorCity,
            vendorPincode
          }
        })
        const fetchPurchaseOrderMetaData = result.map(po => {
          const PO = po.purchaseCode
          const date = moment
            .utc(po.createdAt)
            .add(5, 'hours')
            .add(30, 'minutes')
            .format('DD/MM/YYYY')
          return { PO, date }
        })
        setPurchaseOrderMetaData([[...fetchPurchaseOrderMetaData]])
        setVendorData([[...vendorInfo]])
        setData([[...purchaseOrderData]])
        setIsModalOpen(true)
      }
      if (!res.ok) {
        setError('Error while creating purchase order')
      }
      setBuffer(false)
    }
  }
  const createPo = async (orderTypePoObj) => {
    setBuffer(true);
    const isReqDataClean = await validatePoObjReqData(orderTypePoObj)
    const orderTypePoList = Object.values(orderTypePoObj)


    if (isReqDataClean === false) {
      setError('Please fill all the values')
      setBuffer(false)
    } else {
      try {
        let purchaseOrderMetaDataList = [], dataSet = [], vendorDataSet = [];

        await Promise.all(orderTypePoList.map(async (singleOrder, index) => {
          const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/order/orderRequest`
          let forecastReqBody = []
          const currentForecastId = Object.keys(purchaseOrderReqDataIdObj)[0]
          let forecastReqBodyObj = {
            forecastId: currentForecastId,
            returnFabricId: singleOrder?.returnFabricId,
            purchaseRate: singleOrder?.purchaseRate,
            moq: singleOrder?.moq,
            quantity: singleOrder?.quantity,
            orderType: Object.values(orderTypes).filter((orderType) => orderType.orderTypeName == singleOrder?.orderType)[0]?.orderType,
            expectedDeliveryDate: singleOrder?.expectedDeliveryDate,
            amount: singleOrder?.amount,
            fabricWiseData: singleOrder?.fabricWiseData
          }
          if (singleOrder?.issuanceFabricId)
            forecastReqBodyObj.issuanceFabricId = singleOrder?.issuanceFabricId
          forecastReqBody.push(forecastReqBodyObj)
          const currentVendor = singleOrder?.vendor
          const orderType = Object.values(orderTypes).filter((orderType) => orderType.orderTypeName == singleOrder?.orderType)[0]?.orderType
          const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
              vendorId: currentVendor?.vendorMasterId,
              inHaryana: currentVendor?.inHaryana,
              raisedBy: await cookies.get('id'),
              orderType: orderType,
              forecast: forecastReqBody
            }),
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow'
          }

          const res = await fetch(url, requestOptions)
          const result = await res.json();
          if (res.ok) {
            setError(null)
            setSuccess('Purchase order created successfully')
            const purchaseOrderData = result.map((po, SR_No) => {
              const Fabric_ID = po.returnFabricId
              const Product_Name = po.fabricName
              const color = po.color
              const quantity = po.quantity
              const rate = po.rate
              const amount = po.amount
              const igst = po?.igst
              const cgst = po?.cgst
              const sgst = po?.sgst
              SR_No += 1
              return {
                SR_No,
                Fabric_ID,
                Product_Name,
                color,
                quantity,
                rate,
                amount,
                igst,
                cgst,
                sgst
              }
            })
            const vendorInfo = result.map(vm => {
              const vendorName = vm.vendorName
              const vendorGst = vm.gst
              const vendorPhoneNo = vm.phone_number
              const vendorEmail = vm.email
              const vendorAddress = vm.address
              const vendorCity = vm.city
              const vendorPincode = vm.pincode
              const inHaryana = vm.in_haryana

              return {
                vendorName,
                inHaryana,
                vendorGst,
                vendorPhoneNo,
                vendorEmail,
                vendorAddress,
                vendorCity,
                vendorPincode
              }
            })
            const fetchPurchaseOrderMetaData = result.map(po => {
              const PO = po.purchaseCode
              const date = moment
                .utc(po.createdAt)
                .add(5, 'hours')
                .add(30, 'minutes')
                .format('DD/MM/YYYY')
              return { PO, date }
            })


            purchaseOrderMetaDataList.push([...fetchPurchaseOrderMetaData]);
            vendorDataSet.push([...vendorInfo]);
            dataSet.push([...purchaseOrderData]);
          }


          if (!res.ok) {
            setError('Error in saving data')
            throw new Error('failed to create Purchase order for every request')
          }

        }))

        setPurchaseOrderMetaData([...purchaseOrderMetaDataList]);
        setVendorData([...vendorDataSet]);
        setData([...dataSet]);
      }
      catch (err) {
        setError(err);
      }
      setIsModalOpen(true)
      setActiveStep(0)
      setOpenModal(false)

      setBuffer(false)
    }

  }
  useEffect(() => {
    if (
      //add other orderType here
      selectedOrderType?.orderType == 2 ||
      selectedOrderType?.orderType == 3
    ) {
      setSteps([
        'Fill purchase order details',
        'Select Forecast',
        'Select From Inventory',
        'Edit and submit'
      ])
    } else {
      setSteps([
        'Fill purchase order details',
        'Select Forecast',
        'Edit and submit'
      ])
    }
  }, [selectedOrderType?.orderType])

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1
    }
  }))

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4'
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    }
  }))
  function QontoStepIcon(props) {
    const { active, completed, className } = props

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className='QontoStepIcon-completedIcon' />
        ) : (
          <div className='QontoStepIcon-circle' />
        )}
      </QontoStepIconRoot>
    )
  }


  const SelectForecastModal = ({
    selectedOrderType,
    selectedVendor,
    handlePurchaseOrderReqDataIdObj
  }) => {
    const [forecastRows, setForecastRows] = useState([])
    const [forecastColumns, setForecastColumns] = useState([])
    const [selectedForecastId, setSelectedForecastId] = useState([])
    const [forecastBuffer, setForecastBuffer] = useState(false)
    const [addNewRow, setAddNewRow] = useState([
      { ...fabricVendorMappingSchema, vendorMasterId: selectedVendor?.vendorMasterId, createdBy: cookies.get("id") }
    ]);
    const columnsToShow = {
      id: true,
      fabricId: true,
      deliveryExpectedDate: true,
      approvedQuantity: true,
      inventory: true,
      orderType: true
    }
    const handleForecastSubmit = () => {
      let selectedForecastIdObj = {}
      let foreDataIdObj = {}
      for (let i = 0; i < selectedForecastId.length; i++) {
        selectedForecastIdObj[selectedForecastId[i]] = true
      }

      for (let i = 0; i < forecastRows.length; i++) {
        if (selectedForecastIdObj[forecastRows[i]['id']]) {
          const quantity = selectedOrderType?.orderType === 4 ? forecastRows[i]['inventory'] : forecastRows[i]['approvedQuantity'];
          foreDataIdObj[forecastRows[i]['id']] = {
            returnFabricId: forecastRows[i]['fabricId'],
            purchaseRate:
              fabricIdMapping[selectedVendor?.vendorMasterId][forecastRows[i]['fabricId']].purchaseRate,
            moq: fabricIdMapping[selectedVendor?.vendorMasterId][forecastRows[i]['fabricId']].moq,
            quantity: quantity,
            expectedDeliveryDate: null,
            amount:
              quantity *
              fabricIdMapping[selectedVendor?.vendorMasterId][forecastRows[i]['fabricId']].purchaseRate
          }
          if (selectedOrderType?.orderType === 4) {
            foreDataIdObj[forecastRows[i]['id']].inventory = quantity
            foreDataIdObj[forecastRows[i]['id']].fabricWiseData = [{
              quantity: forecastRows[i]['inventory'],
              itemId: forecastRows[i]['id'],
              rollId: forecastRows[i]['rollId'],
              baseFabId: forecastRows[i]['fabricId'],
              conversionMethod: 'shrinkage',
              fabricId: forecastRows[i]['fabricId'],
              location: forecastRows[i]['location'],
              outwardQuantity: quantity,
            }
            ]
            foreDataIdObj[forecastRows[i]['id']].maxOutwardQuantity = quantity;
            foreDataIdObj[forecastRows[i]['id']].orderType = selectedOrderType?.orderTypeName
          }

        }

      }
      handlePurchaseOrderReqDataIdObj(foreDataIdObj)
      if (steps?.length == 4) setActiveStep(2)
      else setActiveStep(steps?.length - 1)
    }

    const fetchForecasts = async (selectedVendor, selectedOrderType) => {
      setForecastBuffer(true)
      const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/vendorOrderTypeForecastList`

      const fabricInternalIds = selectedVendor.fabricIds.map(
        item => item.fabricInternalId
      )
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          fabricList: [...fabricInternalIds],
          orderType: selectedOrderType.orderType,
          vendorMasterId: selectedVendor?.vendorMasterId
        }),
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow'
      }
      const res = await fetch(url, requestOptions)
      if (res.ok) {
        let response = await res.json()
        if (response.length) {
          for (let i = 0; i < response.length; i++) {
            response[i]['vendorName'] = selectedVendor['vendorName']
            response[i]['fabricIds'] = selectedVendor['fabricIds']
            response[i]['purchaseRates'] = selectedVendor['purchaseRates']
            response[i]['orderType'] = selectedOrderType['orderTypeName']
            response[i]['deliveryExpectedDate'] = moment(
              response[i]['deliveryExpectedDate']
            ).format('YYYY-MM-DD')
          }
          let forecolumns = []
          for (let key in response[0]) {
            if (columnsToShow[key]) {
              forecolumns.push({
                id: key,
                field: key,
                headerName: key,
                key: key,
                label: key,
                width: 170
              })
            }
          }
          setForecastColumns(forecolumns)
          setForecastRows(response)
        } else {
          setForecastColumns([])
          setForecastRows([])
        }
      }
      if (!res.ok) {
        setError('Error in fetching forecast values')
      }
      setForecastBuffer(false)
    }

    useEffect(() => {
      fetchForecasts(selectedVendor, selectedOrderType)
    }, [selectedVendor, selectedOrderType])

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '28rem',
          justifyContent: 'space-between'
        }}
      >
        {forecastBuffer && <LinearProgress />}
        <div style={{ height: '22rem' }}>
          <AddNewFabricToVendorMapping addNewRow={addNewRow} setAddNewRow={setAddNewRow} setSnackBar={setSnackBarMsg} selectedVendor={selectedVendor} setNewFabricVendorMappingAdded={setNewFabricVendorMappingAdded} />
          {!forecastBuffer && forecastRows.length > 0 && (
            <DataGrid
              rows={forecastRows}
              columns={forecastColumns}
              checkboxSelection={true
                // selectedOrderType?.orderType == 1 ? true : selectedOrderType?.orderType == 4 ? true : false
              }
              onRowSelectionModelChange={newRowSelectionModel => {
                setSelectedForecastId(newRowSelectionModel)
              }}
              selectedForecastId={selectedForecastId}
            />
          )}
        </div>

        {!forecastBuffer && forecastRows.length == 0 && (
          <div
            style={{ display: 'flex', flexDirection: 'column', marginTop: 12 }}
          >
            No Forecast to display
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.8rem' }}>
          <MDButton
            size='large'
            variant='outlined'
            style={{ width: '100%', marginLeft: 2 }}
            onClick={() => {
              setActiveStep(0)
            }}
          >
            Back
          </MDButton>
          <Tooltip
            title='Please select atleast one Fabric'
            disableHoverListener={selectedForecastId.length > 0}
          >
            <div style={{ width: '100%' }}>
              <MDButton
                size='large'
                variant='contained'
                style={{ width: '100%', marginLeft: 2 }}
                onClick={handleForecastSubmit}
                disabled={selectedForecastId.length === 0}
              >
                Next
              </MDButton>
            </div>
          </Tooltip>
        </div>
      </Box>
    )
  }

  const SelectInventoryModal = ({ purchaseOrderReqDataIdObj }) => {
    const theme = createTheme({
      components: {
        MuiDataGrid: {
          styleOverrides: {
            columnHeader: {
              fontSize: '.8rem',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#78797A'
            }
          }
        }
      }
    })

    const purchaseOrderReqDataIdObjWithId = Object.keys(
      purchaseOrderReqDataIdObj
    ).map(key => ({
      ...purchaseOrderReqDataIdObj[key],
      id: key
    }))
    const generateId = () => {
      const timestamp = Date.now()
      const randomSuffix = Math.floor(Math.random() * 10000)
      return `${timestamp}-${randomSuffix}`
    }
    const [rowsSet, setRowsSet] = useState([])
    const selectRowsId = useRef([]);
    const purchaseOrderHandler = () => {
      let foreDataIdObj = {}
      rowsSet.forEach(row => {

        let currentRowFabricId =
          row?.orderType == 'purchase'
            ? row?.fabricId
            : row?.returnFabricId
        let issuanceFabricId = row?.orderType == 'purchase'
          ? ''
          : row?.fabricId
        if (selectRowsId.current?.filter((item) => item == row.id)?.length) {
          foreDataIdObj[row?.id] = {
            returnFabricId: currentRowFabricId,
            forecastId: row?.forecastId,
            purchaseRate: fabricIdMapping[selectedVendor?.vendorMasterId]?.[currentRowFabricId]?.purchaseRate || 0,
            moq: fabricIdMapping[selectedVendor?.vendorMasterId]?.[currentRowFabricId]?.moq || 0,
            quantity: '',
            vendor: fabricIdMapping[selectedVendor?.vendorMasterId]?.[currentRowFabricId] ? selectedVendor : '',
            orderType: row?.orderType,
            expectedDeliveryDate: null,
            amount: 0
          }
          if (issuanceFabricId?.length) {
            foreDataIdObj[row?.id]["issuanceFabricId"] = issuanceFabricId;

          }
          if (row?.orderType != 'purchase') {
            foreDataIdObj[row?.id]["maxOutwardQuantity"] = row?.inventory;
            foreDataIdObj[row?.id]["fabricWiseData"] = row?.fabricWiseData;
          }
        }

      })
      setOrderTypePoObj(foreDataIdObj)
    }
    const CustomGrid = ({
      rows,
      selectRowsId
    }) => {
      let columns = []
      columns = [
        {
          field: 'returnFabricId',
          headerName: 'returnFabricId',
          flex: 1,
          align: 'left'
        },
        {
          field: 'orderType',
          headerName: 'Order Type',
          flex: 1,
          align: 'left'
        },
        { field: 'fabricId', headerName: 'Fabric ID', flex: 1, align: 'left' },
        {
          field: 'approvedQuantity',
          headerName: 'approvedQuantity',
          flex: 1,
          align: 'left'
        },
        { field: 'inventory', headerName: 'Inventory', flex: 1, align: 'left' }
      ]
      return (
        <div style={{ height: `${4 * rows?.length}rem`, overflow: 'auto' }}>
          <DataGrid
            rows={rows}
            columnHeaderHeight={30}
            columns={columns}
            hideFooter
            hideFooterPagination

            checkboxSelection={true}
            onRowSelectionModelChange={newRowSelectionModel => {
              selectRowsId.current = newRowSelectionModel
              return newRowSelectionModel
            }}
          />
        </div>
      )
    }
    const fetchFabricOrderTypeInventory = async (
      fabricIds
    ) => {
      const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/orderTypesInventory`
      const fabricInternalIds = selectedVendor.fabricIds.map(
        item => item.fabricInternalId
      )
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          returnFabricIds: fabricIds
        }),
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow'
      }
      const res = await fetch(url, requestOptions)
      const responseRowsData = []
      if (res.ok) {
        const responseData = await res.json()
        let responseFabricWiseData = {}
        responseData.forEach((el) => {
          if (el?.conversionMethod !== selectedOrderType?.orderTypeName)
            return;
          if (responseFabricWiseData[el?.baseFabId] === undefined) {
            let newId = generateId()
            responseFabricWiseData[el?.baseFabId] = {};
            responseFabricWiseData[el?.baseFabId] = {
              id: newId,
              orderType: el?.conversionMethod,
              fabricId: el?.baseFabId,
              returnFabricId: el?.fabricId,
              inventory: el?.quantity,
              itemId: el?.itemId,
              selected: false,
              fabricWiseData: [el]
            }
          }
          else {
            responseFabricWiseData[el?.baseFabId].fabricWiseData.push(el);
            responseFabricWiseData[el?.baseFabId].inventory += parseInt(el?.quantity);
          }
        })
        if (selectedOrderType.orderType == 2 || selectedOrderType.orderType == 3) {
          purchaseOrderReqDataIdObjWithId?.forEach((item) => {
            Object.values(responseFabricWiseData)?.forEach((rItem) => {
              let newId = generateId();
              if (rItem.returnFabricId == item?.returnFabricId)
                responseRowsData.push({ ...rItem, approvedQuantity: item?.quantity, forecastId: item?.id, id: newId, });
            })
          })
        }
        else
          responseRowsData = [...Object.values(responseFabricWiseData)];
      }
      if (!res.ok) {
        setError('Error in fetching fabric orderType inventory ')
      }
      setRowsSet(responseRowsData)
    }

    const handleIventoryModalSubmit = () => {
      setActiveStep(steps?.length - 1)
      purchaseOrderHandler()
    }
    useEffect(() => {
      fetchFabricOrderTypeInventory(
        purchaseOrderReqDataIdObjWithId.map((item) => item?.returnFabricId)
      )
    }, [])
    return (
      <>
        <div style={{ height: '35vh', width: '100%', overflow: 'auto' }}>
          <CustomGrid
            rows={rowsSet}
            selectRowsId={selectRowsId}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.8rem' }}>
          <MDButton
            size='large'
            variant='outlined'
            style={{ width: '100%', marginLeft: 2 }}
            onClick={() => {
              setActiveStep(steps?.length - 3)
            }}
          >
            Back
          </MDButton>
          <Tooltip>
            <div style={{ width: '100%' }}>
              <MDButton
                size='large'
                variant='contained'
                style={{ width: '100%', marginLeft: 2 }}
                onClick={handleIventoryModalSubmit}
              >
                Next
              </MDButton>
            </div>
          </Tooltip>
        </div>
      </>
    )
  }
  useEffect(() => {
    fetchVendorList()
    fetchOrderType()
  }, [])
  useEffect(() => {
    if (newFabricVendorMappingAdded === 1) {
      fetchVendorList()
      fetchOrderType()
      setNewFabricVendorMappingAdded(0);
      setActiveStep(0);
    }
  }, [newFabricVendorMappingAdded])
  return (
    <>
      {success && (
        <MDAlert color='success' dismissible='true'>
          {success}
        </MDAlert>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <MDButton
          variant='outlined'
          size='medium'
          style={{ textTransform: 'capitalize' }}
          onClick={handleOpen}
          circular
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                fontSize: '1.5rem',
                margin: '0.1rem',
                marginRight: '.6rem'
              }}
            >
              +
            </div>
            <div
              style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '1rem' }}
            >
              create PO
            </div>
          </div>
        </MDButton>
        {data?.length ?
          <PDFGenerator
            data={data}
            vendorData={vendorData}
            purchaseOrderMetaData={purchaseOrderMetaData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          /> : <></>
        }
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <MDBox sx={style}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
            {error && (
              <Snackbar
                open={error?.length}
                autoHideDuration={1500}
                onClose={() => {
                  setError(null)
                }}
                message={error}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              />
            )}
          </div>
          {!buffer
            ? activeStep === 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: 20,
                  gap: '2rem'
                }}
              >
                <Snackbar
                  open={snackBarMsg?.length}
                  autoHideDuration={3000}
                  onClose={() => setSnackBarMsg('')}
                  message={snackBarMsg}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: '1rem'
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Vendors</InputLabel>
                    <Select
                      value={selectedVendor}
                      label='Vendors'
                      onChange={e => setSelectedVendor(e.target.value)}
                    >
                      {vendors?.map(row => (
                        <MenuItem value={row}>
                          <MDTypography>{row.vendorName}</MDTypography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>Order Type</InputLabel>
                    <Select
                      value={selectedOrderType}
                      label='Order Type'
                      onChange={e => setSelectedOrderType(e.target.value)}
                    >
                      {orderTypes.map(row => (
                        <MenuItem value={row}>{row.orderTypeName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <Tooltip
                  title='Please select vendor and order type'
                  disableHoverListener={
                    !(selectedVendor === null || selectedOrderType === null)
                  }
                >
                  <div
                    style={{
                      width: '100%',
                      marginBottom: '0.5rem',
                      marginTop: 12
                    }}
                  >
                    <MDButton
                      size='large'
                      variant='contained'
                      disabled={
                        selectedVendor === null || selectedOrderType === null
                      }
                      onClick={() => setActiveStep(1)}
                      style={{ width: '100%', marginLeft: 2 }}
                    >
                      Next
                    </MDButton>
                  </div>
                </Tooltip>
              </div>
            )
            : activeStep === 0 && (
              <div
                style={{ marginTop: 20, marginBottom: 20, marginLeft: '50%' }}
              >
                <LinearProgress />
              </div>
            )}

          {!buffer
            ? activeStep === 1 && (
              <SelectForecastModal
                selectedVendor={selectedVendor}
                selectedOrderType={selectedOrderType}
                handlePurchaseOrderReqDataIdObj={
                  handlePurchaseOrderReqDataIdObj
                }
              />
            )
            : activeStep === 1 && (
              <div
                style={{ marginTop: 20, marginBottom: 20, marginLeft: '50%' }}
              >
                <LinearProgress />
              </div>
            )}
          {!buffer
            ? activeStep === 2 &&
            steps?.length == 4 && (
              <SelectInventoryModal
                purchaseOrderReqDataIdObj={purchaseOrderReqDataIdObj}
              />
            )
            : activeStep === 2 &&
            steps?.length == 4 && (
              <div
                style={{ marginTop: 20, marginBottom: 20, marginLeft: '50%' }}
              >
                <LinearProgress />
              </div>
            )}

          {!buffer
            ? activeStep === steps?.length - 1 && (
              <Box
                style={{
                  ...style,
                  height: '65%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Snackbar
                  open={alertOpen}
                  autoHideDuration={1000}
                  onClose={() => setAlertOpen(false)}
                  message={alertMessage}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                <TableContainer
                  style={{ overflow: 'scroll', height: '40vh' }}
                >
                  {(selectedOrderType?.orderType == 1 || selectedOrderType?.orderType == 4) ? (
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' size='small'>
                            Return Fabric Id
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Purchase Rate
                          </TableCell>
                          <TableCell align='center' size='small'>
                            MinimumOrderQuantity(moq)
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Approved Quantity
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Expected Delivery Date
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Amount (In INR)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(purchaseOrderReqDataIdObj).map(
                          ([key, row]) => (
                            <TableRow key={key}>
                              <TableCell align='center' size='small'>
                                {row.returnFabricId}
                              </TableCell>

                              <TableCell align='center' size='small'>
                                <div>
                                  <Dialog
                                    fullWidth
                                    open={isDialogOpen}
                                    onClose={handleCloseDialog}
                                    sx={{}}
                                  >
                                    <DialogTitle
                                      sx={{
                                        fontSize: '30px',
                                        textAlign: 'end'
                                      }}
                                    >
                                      Edit Fabric{' '}
                                      {
                                        purchaseOrderReqDataIdObj[
                                          editingRowId
                                        ]?.returnFabricId
                                      }{' '}
                                      Purchase Rate
                                    </DialogTitle>
                                    <DialogContent>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'flex-end'
                                        }}
                                      >
                                        <MDInput
                                          type='number'
                                          value={editedPurchaseRate}
                                          onChange={handleRateInputChange}
                                          size='small'
                                        />
                                      </div>
                                    </DialogContent>
                                    <DialogActions>
                                      <MDButton
                                        onClick={() => {
                                          handleRateSaveClick(
                                            setPurchaseOrderReqDataIdObj,
                                            purchaseOrderReqDataIdObj[
                                              editingRowId
                                            ].returnFabricId,
                                            selectedVendor
                                          )
                                          handleCloseDialog()
                                        }}
                                      >
                                        Save
                                      </MDButton>
                                      <MDButton
                                        variant='outlined'
                                        onClick={handleCloseDialog}
                                      >
                                        Cancel
                                      </MDButton>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div
                                    style={{
                                      marginRight: '8px',
                                      fontSize: '16px'
                                    }}
                                  >
                                    {row.purchaseRate}
                                  </div>
                                  <EditIcon
                                    fontSize='medium'
                                    onClick={() => {
                                      setEditingRowId(key)
                                      setDialogOpen(true)
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align='center' size='small'>
                                {row.moq}
                              </TableCell>

                              <TableCell align='center' size='small'>
                                <TextField
                                  id='standard-required'
                                  label='Quantity'
                                  variant='standard'
                                  value={row.quantity}
                                  onChange={e =>
                                    handleEditChange(
                                      e,
                                      purchaseOrderReqDataIdObj,
                                      setPurchaseOrderReqDataIdObj,
                                      'quantity',
                                      key
                                    )
                                  }
                                  type='number'
                                  required
                                ></TextField>
                              </TableCell>
                              <TableCell align='center' size='small'>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label='Start Date'
                                    value={row.expectedDeliveryDate}
                                    format='YYYY-MM-DD'
                                    sx={{ minWidth: '10rem' }}
                                    onChange={e =>
                                      handleEditChange(
                                        e,
                                        purchaseOrderReqDataIdObj,
                                        setPurchaseOrderReqDataIdObj,
                                        'expectedDeliveryDate',
                                        key
                                      )
                                    }
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell align='center' size='small'>
                                {row.amount}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' size='small'>
                            Return Fabric Id
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Purchase Rate
                          </TableCell>
                          <TableCell align='center' size='small'>
                            MinimumOrderQuantity(moq)
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Quantity
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Vendors
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Expected Delivery Date
                          </TableCell>
                          <TableCell align='center' size='small'>
                            Amount (In INR)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(orderTypePoObj).map(([key, row]) => {

                          return (
                            <TableRow key={key}>
                              <TableCell align='center' size='small'>
                                {row.returnFabricId}
                              </TableCell>
                              <TableCell align='center' size='small'>
                                <div>
                                  <Dialog
                                    fullWidth
                                    open={isDialogOpen}
                                    onClose={handleCloseDialog}
                                    sx={{}}
                                  >
                                    <DialogTitle
                                      sx={{
                                        fontSize: '30px',
                                        textAlign: 'end'
                                      }}
                                    >
                                      Edit Fabric{' '}
                                      {
                                        orderTypePoObj[editingRowId]
                                          ?.returnFabricId
                                      }{' '}
                                      Purchase Rate
                                    </DialogTitle>
                                    <DialogContent>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'flex-end'
                                        }}
                                      >
                                        <MDInput
                                          type='number'
                                          value={editedPurchaseRate}
                                          onChange={handleRateInputChange}
                                          size='small'
                                        />
                                      </div>
                                    </DialogContent>
                                    <DialogActions>
                                      <MDButton
                                        onClick={() => {
                                          handleRateSaveClick(
                                            setOrderTypePoObj,
                                            orderTypePoObj[editingRowId]
                                              .returnFabricId,
                                            row?.vendor
                                          )
                                          handleCloseDialog()
                                        }}
                                      >
                                        Save
                                      </MDButton>
                                      <MDButton
                                        variant='outlined'
                                        onClick={handleCloseDialog}
                                      >
                                        Cancel
                                      </MDButton>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div
                                    style={{
                                      marginRight: '8px',
                                      fontSize: '16px'
                                    }}
                                  >
                                    {row.purchaseRate}
                                  </div>
                                  <EditIcon
                                    fontSize='medium'
                                    onClick={() => {
                                      setEditingRowId(key)
                                      setDialogOpen(true)
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align='center' size='small'>
                                {row.moq}
                              </TableCell>

                              <TableCell align='center' size='small'>
                                {row?.orderType == 'purchase' ? <TextField
                                  id='standard-required'
                                  label='Quantity'
                                  variant='standard'
                                  value={row.quantity}
                                  onChange={e =>
                                    handleEditChange(
                                      e,
                                      orderTypePoObj,
                                      setOrderTypePoObj,
                                      'quantity',
                                      key
                                    )
                                  }
                                  type='number'
                                  required
                                ></TextField> :

                                  <TableCell>
                                    <Box sx={{ width: '25rem' }}>
                                      {row?.fabricWiseData.map((item, index) => (item?.outwardQuantity !== undefined && item?.outwardQuantity !== '') ? <Chip sx={{ mx: '1px' }} label={`${item.baseFabId}: ${item.location}:${item.outwardQuantity}`} size='small' onDelete={() => handleDelete(index, orderTypePoObj, key, setOrderTypePoObj)} /> : <></>)}
                                      <Chip color="primary" label='+ Add' size="small" onClick={() => {
                                        setInventoryDialogEditedRow(key);
                                        setIventoryDialogOpen(true);
                                        setInventoryDialogItems(orderTypePoObj?.[key]?.fabricWiseData)
                                      }} />
                                      <InventoryDialog inventoryDailogItems={inventoryDailogItems} setInventoryDialogItems={setInventoryDialogItems} open={inventoryDailogOpen} onClose={handleInventoryDialogOpen} orderTypePoObjRowKey={inventoryDialogEditedRow} orderTypePoObj={orderTypePoObj} setOrderTypePoObj={setOrderTypePoObj} />
                                    </Box>
                                  </TableCell>
                                }
                              </TableCell>
                              <TableCell align='center' size='small'>
                                <FormControl>
                                  <InputLabel>Vendor</InputLabel>
                                  <Select
                                    value={row?.vendor}
                                    label='Vendor'
                                    onChange={e => {
                                      let item = orderTypePoObj
                                      item[key].vendor = e?.target.value;
                                      const selectVendor = item[key].vendor;
                                      item[key].purchaseRate = fabricIdMapping[selectVendor?.vendorMasterId][row?.returnFabricId].purchaseRate
                                      item[key].moq = fabricIdMapping[selectVendor?.vendorMasterId][row?.returnFabricId].moq
                                      item[key].amount = fabricIdMapping[selectVendor?.vendorMasterId][row?.returnFabricId].purchaseRate * item[key].quantity;
                                      setOrderTypePoObj({ ...item })
                                    }}
                                  >
                                    {vendors.filter(vendorRow => {
                                      const isFabricIdAvailable = vendorRow?.fabricIds?.filter(item => {
                                        return item.fabricInternalId === row?.returnFabricId
                                      })
                                      if (isFabricIdAvailable?.length)
                                        return vendorRow
                                    }).map(vendorRow => (
                                      <MenuItem value={vendorRow}>
                                        <MDTypography>
                                          {vendorRow.vendorName}
                                        </MDTypography>
                                      </MenuItem>
                                    ))
                                    }
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align='center' size='small'>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label='Start Date'
                                    value={row.expectedDeliveryDate}
                                    format='YYYY-MM-DD'
                                    sx={{ minWidth: '10rem' }}
                                    onChange={e =>
                                      handleEditChange(
                                        e,
                                        orderTypePoObj,
                                        setOrderTypePoObj,
                                        'expectedDeliveryDate',
                                        key
                                      )
                                    }
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell align='center' size='small'>
                                {row.amount}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.8rem',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                  }}
                >
                  <MDButton
                    size='large'
                    variant='outlined'
                    style={{ width: '100%' }}
                    onClick={() => {
                      setActiveStep(steps?.length - 2)
                    }}
                  >
                    Back
                  </MDButton>
                  <MDButton
                    size='large'
                    variant='contained'
                    style={{ width: '100%', marginLeft: 2 }}
                    onClick={(selectedOrderType.orderType == 1 || selectedOrderType.orderType == 4) ? () => createPurchaseOrder(purchaseOrderReqDataIdObj, 1) : () => {
                      createPurchaseOrder(orderTypePoObj, 2)
                    }}
                  >
                    Submit
                  </MDButton>
                </div>
              </Box>
            )
            : activeStep === steps?.length - 1 && (
              <div
                style={{ marginTop: 20, marginBottom: 20, marginLeft: '50%' }}
              >
                <LinearProgress />
              </div>
            )}
        </MDBox>
      </Modal>
    </>
  )
}

export default PurchaseOrderCreate
