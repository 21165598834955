import React, { useState } from 'react';
import {
    TextField,
    Snackbar,
    Chip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Alert
}
    from '@mui/material'
const InventoryDialog = ({ inventoryDailogItems, setInventoryDialogItems, open, onClose, orderTypePoObjRowKey, orderTypePoObj, setOrderTypePoObj }) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleTextFieldChange = (index) => (e) => {
        let { value } = e.target;
        const editedRow = orderTypePoObj[orderTypePoObjRowKey];
        let fabricItemIdInventory = 0;
        Object.entries(orderTypePoObj).forEach(([key, po]) => {
            po?.fabricWiseData?.forEach((item, invLocInd) => {
                const fabricWiseDataEl = editedRow?.fabricWiseData?.[index];
                if (orderTypePoObjRowKey !== key && invLocInd == index && item.itemId === fabricWiseDataEl?.itemId && item?.outwardQuantity !== undefined)
                    fabricItemIdInventory += parseFloat(item?.outwardQuantity);
            })
        })
        // Check if value is a number and non-zero
        if (!isNaN(value) && parseFloat(value) !== 0 && parseFloat(value) + fabricItemIdInventory <= parseFloat(inventoryDailogItems[index].quantity)) {
            setInventoryDialogItems((prevItems) =>
                prevItems.map((item, i) => (i === index ? { ...item, outwardQuantity: value } : item))
            );
        }
        else {
            if (parseFloat(value) + fabricItemIdInventory > parseFloat(inventoryDailogItems[index].quantity)) {
                setSnackbarMessage(`Quantity cannot exceed ${inventoryDailogItems[index].quantity}`);
                setSnackbarOpen(true);
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, 2000);
            }
            setInventoryDialogItems((prevItems) =>
                prevItems.map((item, i) => (i === index ? { ...item, outwardQuantity: "" } : item))
            );
        }
    };

    const handleSave = () => {
        // Handle save logic here
        let editedRow = orderTypePoObj[orderTypePoObjRowKey];
        editedRow.fabricWiseData = [...inventoryDailogItems];
        let poQty = inventoryDailogItems.reduce((acc, el) => el?.outwardQuantity !== undefined ? parseFloat(el?.outwardQuantity) + acc : acc, 0);
        editedRow.quantity = poQty;
        editedRow.amount = poQty * (editedRow.purchaseRate)
        orderTypePoObj[orderTypePoObjRowKey] = editedRow;
        setOrderTypePoObj({ ...orderTypePoObj })
        onClose();
    };
    const handleCancel = () => {
        // Handle cancel logic here
        setInventoryDialogItems((prevItems) =>
            prevItems.map((item, i) => ({ ...item, outwardQuantity: "" }))
        );
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} sx={{ top: '20%', left: '0%', height: '70vh' }}>
            <Snackbar
                open={snackbarOpen}
                message={snackbarMessage}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "bottom" }}
            >
                <Alert severity="warning">{snackbarMessage}</Alert>
            </Snackbar>

            <Box boxShadow={3}> <DialogTitle>Select fabric {inventoryDailogItems?.[0]?.baseFabId} from locations to sent for {inventoryDailogItems?.[0]?.conversionMethod}</DialogTitle>
            </Box>
            <DialogContent sx={{ overflowY: 'auto' }}>
                {inventoryDailogItems?.map((item, index) => (
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Chip size='medium' label={`Location:${item.location} `} />
                            <Chip size='medium' label={`Inventory: ${item.quantity}`} />
                        </Box>
                        <TextField
                            key={index}
                            label={item.location}
                            placeholder={item.location}
                            value={item.outwardQuantity}
                            onChange={handleTextFieldChange(index)}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0 }}
                        />
                    </Box>

                ))}
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
}

export default InventoryDialog;