import { React, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import {
    Modal,
    Button,
    CircularProgress,
    Stepper,
    Step,
    StepLabel,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    TableHead,
    Snackbar,
    Paper,
    Alert,
    Autocomplete,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import { styled } from "@mui/material/styles";
import DropDown from '../../dropDown/dropDown';
import MDButton from '../../../components/MDButton';
import MDBox from '../../../components/MDBox';
import { DataGrid } from '@mui/x-data-grid';
import GrnPdfGenerator from '../../pdfGenerator/grnPdfGenerator'
import { getOrderTypeName } from '../../../orderTypes/orderTypesEnum'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function SelectVendorAndPOAndOpenGrn() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [activeStep, setActiveStep] = useState(0);
    const [vendors, setVendors] = useState([]);
    const [selectValue, setSelectValue] = useState("");
    const [rows, setRows] = useState([]);
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState("");
    const [uniquePurchaseCodes, setUniquePurchaseCodes] = useState([]);
    const [selectPurchaseCode, setSelectPurchaseCode] = useState("")
    const [doGrnModalRow, setDoGrnModalRow] = useState({});
    const [selectedVendorName, setSelectedVendorName] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [closeGrnModal, setCloseGrnModal] = useState(0);

    const fetchVendorList = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/vendorList`;
        const requestOptions = {
            method: "GET",
        }
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            let response = await res.json();
            let vendorsObj = {};

            for (let i = 0; i < response.length; i++) {
                const vendorName = response[i].vendorName;
                const vendorInternalId = response[i].vendorInternalId;
                const fabricInternalId = response[i].fabricInternalId;
                const inHaryana = response[i].inHaryana;
                const vendorMasterId = response[i].vendor_master_id;
                const fabricMasterID = response[i].fabric_master_id;
                if (vendorsObj[vendorInternalId]) {
                    vendorsObj[vendorInternalId].fabricIds.push({ fabricInternalId, fabricMasterID });
                }
                else {
                    vendorsObj[vendorInternalId] = {
                        "fabricIds": [{ fabricInternalId, fabricMasterID }],
                        "vendorName": vendorName,
                        "vendorMasterId": vendorMasterId,
                        "inHaryana": inHaryana
                    }
                }
            }
            let vendorsList = [];
            for (let key in vendorsObj) {
                vendorsList.push(
                    {
                        label: vendorsObj[key].vendorName,
                        key: vendorsObj[key].vendorMasterId,
                        value: vendorsObj[key].vendorMasterId,
                    }
                )
            }
            setVendors(vendorsList);

        }
        if (!res.ok) {
            setError("Error in fetching Vendors list");
        }
    }

    const fetchLocationList = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/inventoryLocation/list`;
        const options = {
            'method': 'GET',
        };
        const res = await fetch(url, options);
        let data = await res.json();
        data = data.map((item) => item.location);
        setLocationList(data);
    }

    const fetchOpenPurchaseOrder = async (filters) => {
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/purchaseOrder`;
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            redirect: 'follow',
            body: JSON.stringify({
                "filters": {
                    "returnFabricId": filters && filters.returnFabricId ? filters.returnFabricId.value : null,
                    "purchaseCode": filters && filters.purchaseOrderCode ? filters.purchaseOrderCode.value : null
                },
                "vendorFilter": {
                    "vendorMasterId": filters && filters.vendorMasterId ? filters.vendorMasterId : null
                },
                "dateFilters": {
                    "expectedDeliveryDate": filters ? [filters.expectedDeliveryDateStart ? filters.expectedDeliveryDateStart.value : null, filters.expectedDeliveryDateEnd ? filters.expectedDeliveryDateEnd.value : null] : [null, null]
                },
                "statusFilters": {
                    "status": filters && filters.status !== undefined ? filters.status : null
                }
            })
        };

        const res = await fetch(url, requestOptions);
        if (res.ok) {
            let data = await res.json();
            data = data.map((item, index) => {
                return { ...item, "id": data[index].purchaseOrderId, "sno": index }
            })
            setRows(data);

            const uniquePurchaseCodesCollection = [...new Set(data.map(item => item.purchaseCode))];
            const resultArray = [];
            for (let key in uniquePurchaseCodesCollection) {
                const currentYear = new Date().getFullYear().toString().slice(-2);
                const nextYear = (new Date().getFullYear() + 1).toString().slice(-2);
                resultArray.push(
                    {
                        label: `FS_PO_${currentYear}_${nextYear}_${uniquePurchaseCodesCollection[key]}`,
                        key: key,
                        value: `FS_PO_${currentYear}_${nextYear}_${uniquePurchaseCodesCollection[key]}`
                    }
                )
            }
            setUniquePurchaseCodes(resultArray)
            setError(null);
        }
        if (!res.ok) {
            setError('Error in displaying purchase order data');
        }
    }

    const steps = [
        "Select Vendor and Purchase order",
        "Select GRN",
        "Fill GRN details and submit",
    ];

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    useEffect(
        () => {
            fetchVendorList()
            for (let vendor of vendors) {
                if (vendor.value == selectValue)
                    setSelectedVendorName(vendor.label);
            }
        }

        , [selectValue])
    useEffect(() => {

        (async () => {
            await fetchOpenPurchaseOrder({
                purchaseOrderCode: {
                    value: selectPurchaseCode.split('_')[4]
                },
                vendorMasterId: selectValue,
                status: [0]
            });
        })();
    }, [selectValue, open])

    useEffect(() => {

        if (activeStep == 2)
            fetchLocationList();

    }, [activeStep])
    useEffect(() => {
        if (open == false) {
            setRows([]);
            setActiveStep(0);
            setSelectValue("");
        }
    }, [open])
    function GrnModal({ selectValue, selectPurchaseCode, rows, setRows }) {
        const columns = [
            {
                id: 'sno',
                field: 'sno',
                align: 'left',
                disablePadding: false,
                headerName: 'GRN',
                key: 'sno',
                label: 'GRN',
                width: 200,
                renderCell: (params) => (
                    <MDButton color="info" size="medium" variant="contained" onClick={() => { setDoGrnModalRow(params.row); setActiveStep(2) }}><ArrowForwardIcon></ArrowForwardIcon>GRN</MDButton>
                )
            },
            {
                field: 'returnFabricId',
                headerName: 'FabricId',
                width: 180
            },
            {
                field: 'orderType',
                headerName: 'order Type',
                width: 200,
                renderCell: (params) => getOrderTypeName(params.value)
            },
            {
                field: 'quantity',
                headerName: 'PoQuantity',
                width: 120
            },
            {
                field: 'recievedQuantity',
                headerName: 'PoFulfilledQuantity',
                width: 200
            },
            {
                field: 'purchaseCode',
                headerName: 'PurchaseCode',
                width: 180,
                renderCell: (params) => {
                    const currentYear = new Date(params?.row?.createdAt).getFullYear().toString().slice(-2);
                    const nextYear = (new Date(params?.row?.createdAt).getFullYear() + 1).toString().slice(-2);
                    return `FS_PO_${currentYear}_${nextYear}_${params.value}`;
                }
            },
            {
                field: 'rate',
                headerName: 'Rate',
                width: 110,
                editable: true
            },
            {
                field: 'issuanceFabricId',
                headerName: 'Issued FabricId',
                width: 200
            },
        ];

        return (
            <>
                <div style={{ display: 'flex', width: "100%", height: '40rem', overflow: 'scroll' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 15, 100]}
                    />
                </div>
            </>
        )
    }


    function DoGrnModal({ row }) {
        const { purchaseOrderId, orderType, purchaseCode, quantity, rate, returnFabricId, recievedQuantity } = row;
        const [grnData, setGrnData] = useState([]);
        const todayDate = new Date();
        const [isValid, setIsValid] = useState(true);
        const [rows, setRows] = useState([{ purchaseOrderId, purchaseCode, orderType, quantity, rate, returnFabricId, recievedQuantity, warningMessage: '', grnQty: '', grnRejectQty: '', location: '', invoiceNumber: '', invoiceDate: todayDate }]);
        const [alertOpen, setAlertOpen] = useState(false);
        const [error, setError] = useState(null);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [alertMessage, setAlertMessage] = useState('');
        const cookies = new Cookies();
        const handleInputChange = (index, columnName, value) => {
            const updatedRows = [...rows];
            if (columnName === 'rate') {
                if (parseInt(value) < 1) {
                    setAlertMessage('Rate should be Positive');
                    setAlertOpen(true);
                    setIsValid(false);
                    return;
                }
            }
            else if (columnName === 'grnQty') {
                if (parseInt(value) < 1) {
                    setAlertMessage('Quantity should be greater than 0');
                    setAlertOpen(true);
                    setIsValid(false);
                    return;
                }
                const grnQty = parseInt(value, 10) || 0;
                const fulfilledQty = parseInt(updatedRows[index].recievedQuantity, 10) || 0;
                let totalgrnQty = 0;
                for (let ind in updatedRows) {
                    if (ind == index)
                        continue;
                    totalgrnQty += parseInt(updatedRows[ind].grnQty);
                }
                const totalQty = grnQty + fulfilledQty + totalgrnQty;
                const qty = parseInt(updatedRows[index].quantity, 10) || 0;
                if (totalQty > qty + (2 * qty) / 10) {
                    updatedRows[index].warningMessage = ` ${totalQty - (qty + (2 * qty / 10))} more than allowed PoQty`;
                    setIsValid(false);

                }
                else {
                    updatedRows[index].warningMessage = '';
                    setIsValid(true);
                }
            }
            updatedRows[index] = { ...updatedRows[index], [columnName]: value };
            setRows([...updatedRows]);
        };

        const handleAddRow = () => {
            const lastRow = rows[rows.length - 1];
            const extractedRow = {
                rate: lastRow.rate,
                grnQty: lastRow.grnQty,
                invoiceNumber: lastRow.invoiceNumber,
                location: lastRow.location,
                invoiceDate: lastRow.invoiceDate,
            };
            const isLastRowValid = Object.values(extractedRow).every(value => value !== '');
            if (!isLastRowValid) {
                setAlertMessage('Please fill in all column in the last row before adding a new one.');
                setAlertOpen(true);
            }
            else
                setRows([...rows, { purchaseOrderId, purchaseCode, orderType, quantity, rate, returnFabricId, recievedQuantity, warningMessage: '', grnQty: '', grnRejectQty: '', location: '', invoiceNumber: '', invoiceDate: todayDate }]);
        };

        const handleRemoveRow = (index) => {
            const updatedRows = [...rows];
            if (updatedRows.length > 1) {
                updatedRows.splice(index, 1);
                setIsValid(true);
            }
            setRows(updatedRows);
        };

        const doGrn = async (grnData) => {
            const grnUrl = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/grn/new/rolls`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        grnData,
                        raisedBy: cookies.get("id")
                    }
                )
            };

            const res = await fetch(grnUrl, requestOptions);
            if (res.ok) {
                const response = await res.json();
                setGrnData(response.map(({ orderType, ...rest }) => rest))
                setIsModalOpen(true);
            }
            else if (!res.ok) {
                setOpen(false);
                setError('Error creating GRN');
            }
        }

        const handleOnSubmit = async () => {
            const rowsData = [];
            for (let rowOb of rows) {
                const { purchaseOrderId, orderType, purchaseCode, quantity, rate, returnFabricId, recievedQuantity,
                    grnQty, grnRejectQty, location, invoiceNumber, invoiceDate } = rowOb;
                const extractedRow = {
                    purchaseOrderId,
                    purchaseCode,
                    orderType,
                    quantity,
                    rate,
                    returnFabricId,
                    recievedQuantity,
                    grnQty,
                    location,
                    invoiceNumber,
                    invoiceDate
                };
                const isRowValid = Object.values(extractedRow).every(value => value !== '');
                extractedRow.grnRejectQty = parseInt(grnRejectQty ? grnRejectQty : 0);
                rowsData.push(extractedRow)
                if (!isRowValid) {
                    setAlertMessage('Please fill in all input field in the last row before adding a new one and check input format');
                    setAlertOpen(true);
                    return;
                }
            }
            if ((!alertOpen) && isValid) {
                await doGrn(rowsData)

            }
        }
        return (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ display: 'flex', width: "100%", height: '25rem', overflow: 'scroll' }}>
                    <GrnPdfGenerator data={grnData} vendor={selectedVendorName} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} activeStep={activeStep} setCloseGrnModal={setOpen} />
                    <Snackbar
                        open={alertOpen}
                        autoHideDuration={1000}
                        onClose={() => setAlertOpen(false)}
                        message={alertMessage}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    />
                    <TableContainer style={{ maxHeight: '24rem' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>PoCode</TableCell>
                                    <TableCell align='center'>orderType</TableCell>
                                    <TableCell align='center'>FabricID</TableCell>
                                    <TableCell align='center'>Rate</TableCell>
                                    <TableCell align='center'>PoQty</TableCell>
                                    <TableCell align='center'>fulfilledQty</TableCell>
                                    <TableCell align='center'>grnQty</TableCell>
                                    <TableCell align='center'>grnRejectQty</TableCell>
                                    <TableCell align='center'>Location</TableCell>
                                    <TableCell align='center'>invoiceNumber</TableCell>
                                    <TableCell align='center'>InvoiceDate</TableCell>
                                    <TableCell align='center'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align='center'>{row.purchaseCode}</TableCell>
                                        <TableCell align='center'>{getOrderTypeName(row.orderType)}</TableCell>
                                        <TableCell align='center'>{row.returnFabricId}</TableCell>
                                        <TableCell align='center' sx={{ minWidth: '7rem' }}>
                                            <TextField
                                                variant="outlined"
                                                value={row.rate}
                                                onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                size="small"
                                                type="number"

                                            />
                                        </TableCell>
                                        <TableCell align='center'>{row.quantity}</TableCell>
                                        <TableCell align='center'>{row.recievedQuantity}</TableCell>
                                        <TableCell align='center'>
                                            <TextField
                                                variant="outlined"
                                                value={row.grnQty}
                                                onChange={(e) => handleInputChange(index, 'grnQty', e.target.value)}
                                                style={{ borderColor: isValid ? '' : 'red', width: '7rem' }}
                                                size="small"
                                                type="number"
                                            />
                                            {row.warningMessage && (
                                                <div style={{ color: 'red', fontSize: '12px', marginTop: '0.25rem' }}>
                                                    {row.warningMessage}
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TextField
                                                variant="outlined"
                                                value={row.grnRejectQty}
                                                onChange={(e) => handleInputChange(index, 'grnRejectQty', e.target.value)}
                                                size="small"
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell align='center' sx={{ minWidth: '12.5rem' }}>

                                            <Autocomplete
                                                options={locationList}
                                                value={row.location}
                                                onChange={(e, newValue) => handleInputChange(index, 'location', newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}

                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TextField
                                                variant="outlined"
                                                value={row.invoiceNumber}
                                                onChange={(e) => handleInputChange(index, 'invoiceNumber', e.target.value)}
                                                size="small"
                                            />
                                        </TableCell>

                                        <TableCell align='center'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Date"
                                                    value={row.invoiceDate ? dayjs(row.invoiceDate) : dayjs()}
                                                    format="DD/MM/YYYY"
                                                    sx={{ minWidth: '10rem' }}
                                                    onChange={(newValue) => {
                                                        const formattedDate = newValue.toISOString();
                                                        handleInputChange(index, 'invoiceDate', formattedDate);
                                                    }}

                                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>

                                        <TableCell align='center'>
                                            <Button
                                                variant="contained" color="success"
                                                size="medium"
                                                sx={{ color: '#0C0705' }}
                                                onClick={() => handleRemoveRow(index)}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <MDButton variant='outlined' sx={{ marginTop: '0.4rem' }} circular size="medium" disabled={!isValid} onClick={handleAddRow}>
                            Add
                        </MDButton>


                    </TableContainer>

                </div>
                <div style={{ display: 'flex', margin: '.5rem', marginTop: '0.2rem', justifyContent: 'space-between' }} >

                    <MDButton variant="outlined" size="medium" onClick={() => setActiveStep(1)} >
                        Back
                    </MDButton>
                    <MDButton variant="contained" size="medium" onClick={handleOnSubmit} >
                        Submit
                    </MDButton>

                </div>
            </Paper>
        )
    }
    return (
        <>
            {error ? <Alert>{error}</Alert> : <></>}
            <MDButton color="info" size="medium" variant="gradient" onClick={handleOpen} >
                GRN
            </MDButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MDBox sx={style}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {!buffer ? (
                        activeStep === 0 && (
                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%", gap: '1rem', marginTop: 20 }}>
                                <DropDown selectValue={selectValue} setSelectValue={setSelectValue} label="vendors" stores={vendors} />
                                <MDButton color="info" variant="contained" size="medium" onClick={() => setActiveStep(1)} disabled={selectValue === ""}>Open</MDButton>
                            </div>
                        )
                    ) : (
                        activeStep === 0 && (
                            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: "50%" }}>
                                <CircularProgress />
                            </div>
                        )
                    )}

                    {!buffer ? (
                        activeStep === 1 && (
                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%", gap: '1rem', marginTop: 20 }}>
                                <GrnModal selectValue={selectValue} selectPurchaseCode={selectPurchaseCode} rows={rows} setRows={setRows} />
                                <MDButton variant="outlined" size="large" sx={{ width: '10%' }} onClick={() => setActiveStep(0)}> Back </MDButton>
                            </div>
                        )
                    ) : (
                        activeStep === 1 && (
                            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: "50%" }}>
                                <CircularProgress />
                            </div>
                        )
                    )}

                    {!buffer ? (
                        activeStep === 2 && (
                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%", gap: '1rem', marginTop: 20 }}>
                                <DoGrnModal row={doGrnModalRow} />
                            </div>
                        )
                    ) : (
                        activeStep === 2 && (
                            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: "50%" }}>
                                <CircularProgress />
                            </div>
                        )
                    )}
                </MDBox>
            </Modal>

        </>
    )
}

export default SelectVendorAndPOAndOpenGrn;