import React from 'react';
import ReactDom from 'react-dom';
import { hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MaterialUIControllerProvider } from "./context";
import { PreSignUrlProvider } from './context/preSignedUrl';

const domNode = document.getElementById('root');
const root = hydrateRoot(domNode,
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <PreSignUrlProvider>
          <App />
        </PreSignUrlProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>,
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

