import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import fabricDetailsSchema from './fabricUtils'
import AddNewFabric from './AddNewFabric';
import { PreSignUrlContext } from '../../../../context/preSignedUrl'
import Cookies from 'universal-cookie';
import {
    CircularProgress, Paper, Alert, Snackbar
} from '@mui/material';
import MDBox from '../../../../components/MDBox';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    p: 4,
}

const SvgComponent = () => {
    return (<svg
        fill="#000000"
        height="50rem"
        width="50rem"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 297 297"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        enableBackground="new 0 0 297 297"
    >
        <g>
            <path d="M287.116,24.462h-89.693c-5.458,0-9.884,4.425-9.884,9.884c0,21.527-17.513,39.04-39.04,39.04s-39.04-17.513-39.04-39.04   c0-5.458-4.425-9.884-9.884-9.884H9.884C4.425,24.462,0,28.887,0,34.345v97.847c0,5.458,4.425,9.883,9.884,9.883H40.77v120.579   c0,5.458,4.425,9.884,9.883,9.884h195.694c5.458,0,9.884-4.425,9.884-9.884V142.076h30.886c5.458,0,9.884-4.425,9.884-9.883V34.345   C297,28.887,292.575,24.462,287.116,24.462z M277.233,122.309h-30.886c-5.458,0-9.883,4.425-9.883,9.883v120.579H60.537V132.192   c0-5.458-4.425-9.883-9.883-9.883H19.767v-78.08h70.757c4.712,27.738,28.916,48.923,57.976,48.923s53.264-21.185,57.976-48.923   h70.757V122.309z" />
            <path d="m152.363,108.848c-2.691,0.125-5.431,0.104-8.14-0.061-3.814-0.233-7.095,2.669-7.329,6.483-0.232,3.796 2.642,7.064 6.429,7.325 0.018,0.001 0.037,0.003 0.054,0.004 3.204,0.196 6.445,0.22 9.631,0.071 3.816-0.178 6.766-3.416 6.588-7.233-0.177-3.817-3.407-6.769-7.233-6.589z" />
            <path d="m186.662,98.602c-2.332,1.377-4.76,2.638-7.217,3.748-3.482,1.573-5.03,5.671-3.457,9.153 1.086,2.405 3.375,3.886 5.835,4.056 1.104,0.076 2.241-0.111 3.318-0.598 2.915-1.316 5.794-2.812 8.556-4.443 3.291-1.943 4.383-6.185 2.44-9.474-1.944-3.294-6.186-4.386-9.475-2.442z" />
            <path d="m117.206,101.942c-2.446-1.143-4.858-2.436-7.171-3.843-3.261-1.984-7.52-0.949-9.507,2.316-1.986,3.264-0.949,7.521 2.316,9.507 2.742,1.667 5.602,3.201 8.503,4.556 0.796,0.372 1.625,0.579 2.451,0.636 2.77,0.191 5.5-1.309 6.746-3.975 1.619-3.462 0.123-7.579-3.338-9.197z" />
            <path d="m84.775,72.685c-1.968-3.276-6.219-4.334-9.493-2.368-3.275,1.968-4.336,6.218-2.368,9.493 1.647,2.742 3.466,5.427 5.406,7.981 1.257,1.655 3.114,2.585 5.036,2.718 1.612,0.111 3.27-0.338 4.658-1.393 3.043-2.311 3.636-6.651 1.324-9.694-1.637-2.157-3.173-4.424-4.563-6.737z" />
            <path d="m208.809,89.922c1.129,0.883 2.448,1.36 3.778,1.451 2.215,0.153 4.463-0.761 5.934-2.642 1.974-2.525 3.83-5.187 5.517-7.912 2.012-3.248 1.009-7.513-2.24-9.523-3.247-2.014-7.512-1.01-9.523,2.24-1.424,2.298-2.99,4.544-4.655,6.675-2.355,3.009-1.822,7.357 1.189,9.711z" />
            <path d="m83.269,117.12c-3.821,0-6.919,3.097-6.919,6.919v4.448c0,3.821 3.097,6.918 6.919,6.918s6.918-3.097 6.918-6.918v-4.448c-1.42109e-14-3.822-3.097-6.919-6.918-6.919z" />
            <path d="m83.269,150.964c-3.821,0-6.919,3.097-6.919,6.918v8.534c0,3.821 3.097,6.918 6.919,6.918s6.918-3.097 6.918-6.918v-8.534c-1.42109e-14-3.821-3.097-6.918-6.918-6.918z" />
            <path d="m83.269,188.895c-3.821,0-6.919,3.097-6.919,6.918v8.535c0,3.821 3.097,6.918 6.919,6.918s6.918-3.097 6.918-6.918v-8.535c-1.42109e-14-3.821-3.097-6.918-6.918-6.918z" />
            <path d="m83.269,226.827c-3.821,0-6.919,3.097-6.919,6.918v4.448c0,3.821 3.097,6.919 6.919,6.919s6.918-3.097 6.918-6.919v-4.448c-1.42109e-14-3.821-3.097-6.918-6.918-6.918z" />
            <path d="m213.731,117.12c-3.821,0-6.919,3.097-6.919,6.919v4.448c0,3.821 3.097,6.918 6.919,6.918 3.821,0 6.919-3.097 6.919-6.918v-4.448c0-3.822-3.098-6.919-6.919-6.919z" />
            <path d="m213.731,188.895c-3.821,0-6.919,3.097-6.919,6.918v8.535c0,3.821 3.097,6.918 6.919,6.918 3.821,0 6.919-3.097 6.919-6.918v-8.535c0-3.821-3.098-6.918-6.919-6.918z" />
            <path d="m213.731,150.964c-3.821,0-6.919,3.097-6.919,6.918v8.534c0,3.821 3.097,6.918 6.919,6.918 3.821,0 6.919-3.097 6.919-6.918v-8.534c0-3.821-3.098-6.918-6.919-6.918z" />
            <path d="m213.731,226.827c-3.821,0-6.919,3.097-6.919,6.918v4.448c0,3.821 3.097,6.919 6.919,6.919 3.821,0 6.919-3.097 6.919-6.919v-4.448c0-3.821-3.098-6.918-6.919-6.918z" />
        </g>
    </svg>
    )
}

// ==============================|| ORDER TABLE ||============================== //
const FabricDetails = () => {
    const [rows, setRows] = useState([]);
    const cookies = new Cookies();
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const [rowSelectionModel, setRowSelectionModal] = useState([]);
    const [addNewRow, setAddNewRow] = useState([
        { ...fabricDetailsSchema, createdBy: cookies.get("id") }
    ]);
    // preSignedUrl
    const { preSignedUrls, fetchPreSignedUrl } = useContext(PreSignUrlContext)
    const [snackBar, setSnackBar] = useState("");
    const secondsToDays = (seconds) => {
        const secondsInADay = 86400;
        return seconds / secondsInADay;
    }
    const columns = [
        {
            id: 'id',
            field: 'id',
            align: 'left',
            disablePadding: false,
            headerName: 'Id',
            key: 'id',
            label: 'Id',
            width: 100,
        },
        {
            id: 'fabric_id',
            field: 'fabric_id',
            align: 'left',
            disablePadding: false,
            headerName: 'Fabric Id',
            key: 'fabric_id',
            label: 'Fabric Id',
            width: 200,
        },
        {
            id: 'fabric_name_external',
            field: 'fabric_name_external',
            align: 'left',
            disablePadding: false,
            headerName: 'fabric_name_external',
            label: 'fabric_name_external',
            width: 200,
        },
        {
            id: 'fabric_name_internal',
            field: 'fabric_name_internal',
            align: 'left',
            disablePadding: false,
            headerName: 'Fabric_name_internal',

            label: 'fabric_name_internal',
            width: 200,
        },
        {
            id: 'image',
            field: 'image',
            align: 'left',
            disablePadding: false,
            headerName: 'Image',
            label: 'image',
            renderCell: (params) => (params.value === 'sample' ? <SvgComponent />
                : <img src={preSignedUrls?.[params?.row?.fabric_id]?.url} alt="fabric" />),
            width: 100,
        },
        {
            id: 'color',
            field: 'color',
            align: 'left',
            disablePadding: false,
            headerName: 'Color',
            label: 'color',
            width: 100,
        },

        {
            id: 'status',
            field: 'status',
            align: 'left',
            disablePadding: false,
            headerName: 'Status',
            label: 'status',
            width: 100,
        },
        {
            id: 'current_inventory',
            field: 'current_inventory',
            align: 'left',
            disablePadding: false,
            headerName: 'Inventory',
            label: 'current_inventory',
            width: 100,
        },
        {
            id: 'moq',
            field: 'moq',
            align: 'left',
            disablePadding: false,
            headerName: 'Moq',
            label: 'moq',
            width: 100,
        },
        {
            id: 'po_avg_rate',
            field: 'po_avg_rate',
            align: 'left',
            disablePadding: false,
            headerName: 'Avg_po_rate',
            label: 'po_avg_rate',
            width: 150,
            renderCell: (params) => params?.value?.toFixed(2)
        },
        {
            id: 'grn_avg_rate',
            field: 'grn_avg_rate',
            align: 'left',
            disablePadding: false,
            headerName: 'Grn_avg_rate',
            label: 'grn_avg_rate',
            width: 150,
            renderCell: (params) => params?.value?.toFixed(2)

        },
        {
            id: 'min_replenishment',
            field: 'min_replenishment',
            align: 'left',
            disablePadding: false,
            headerName: 'Min_replenishment',
            label: 'min_replenishment',
            width: 200,
            renderCell: (params) => `${secondsToDays(params?.value).toFixed(4)} days`

        },
        {
            id: 'max_replenishment',
            field: 'max_replenishment',
            align: 'left',
            disablePadding: false,
            headerName: 'Max_replenishment',
            label: 'max_replenishment',
            width: 200,
            renderCell: (params) => `${secondsToDays(params?.value).toFixed(4)} days`
        },
    ];

    const fetchInventoryView = async () => {
        try {
            setBuffer(true);
            let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/fabricMaster/details/fabricDetails`;
            const requestOptions = {
                method: 'GET',
                headers: { "Content-Type": "application/json" },
                redirect: 'follow',
            };
            const res = await fetch(url, requestOptions);

            if (res.ok) {
                let data = await res.json();
                if (Object?.keys(preSignedUrls)?.length == 0)
                    fetchPreSignedUrl(data.map((item) => item.fabric_id));
                setRows(data);

            }
            if (!res.ok) {
                setError('Error in displaying fabricDetails data');
            }
            setBuffer(false);
        }
        catch (error) {
            setError('Error fetching  fabricDetails data')
        }
    }

    useEffect(() => {
        (async () => {
            await fetchInventoryView();
        })()
    }, [])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
            <MDBox>
                <Snackbar
                    open={snackBar != 0}
                    autoHideDuration={1500}
                    onClose={() => {
                        setSnackBar(0)
                    }}
                    // message="test"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity={snackBar == 1 ? 'success' : snackBar == 2 ? 'error' : ''} >{snackBar == 1 ? 'New FabricIds Inserted Successfully' : snackBar == 2 ? 'failed to create  new FabricIds' : ''}</Alert>
                </Snackbar>
                <AddNewFabric addNewRow={addNewRow} setAddNewRow={setAddNewRow} snackBar={snackBar} setSnackBar={setSnackBar}
                    fabricIds={rows?.map((item) => item.fabric_id)}
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                        <div style={{ height: "56.875rem", width: '100%' }}>
                            {buffer && <div style={{
                                textAlign: 'center',
                                padding: '20vh 0vh',
                                marginLeft: '50%'
                            }}>
                                <CircularProgress />
                            </div>}
                            {!buffer && (error == null) &&
                                <DataGrid
                                    sx={{ height: '100%' }}
                                    rows={rows}
                                    rowHeight={50}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 50 },
                                        },
                                    }}
                                    onRowSelectionModelChange={(newRowSelectionModel) => {
                                        setRowSelectionModal(newRowSelectionModel)
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    pageSizeOptions={[50, 75, 100]}

                                />

                            }
                            {!buffer && (error != null) && <Alert severity='error' dismissible='true'>{error}</Alert>}
                        </div>
                    </div>
                </div>
            </MDBox>
        </Paper >
    );
}
export default FabricDetails;