import React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import { PreSignUrlContext } from '../../../context/preSignedUrl'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import Cookies from 'universal-cookie';
import {
    CircularProgress, TextField, Paper, Alert, Box
} from '@mui/material';
import MDBox from '../../../components/MDBox';
import MDButton from '../../../components/MDButton';
import { CSVLink } from "react-csv";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    p: 4,
}

const SvgComponent = () => {
    return (<svg
        fill="#000000"
        height="50rem"
        width="50rem"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 297 297"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        enableBackground="new 0 0 297 297"
    >
        <g>
            <path d="M287.116,24.462h-89.693c-5.458,0-9.884,4.425-9.884,9.884c0,21.527-17.513,39.04-39.04,39.04s-39.04-17.513-39.04-39.04   c0-5.458-4.425-9.884-9.884-9.884H9.884C4.425,24.462,0,28.887,0,34.345v97.847c0,5.458,4.425,9.883,9.884,9.883H40.77v120.579   c0,5.458,4.425,9.884,9.883,9.884h195.694c5.458,0,9.884-4.425,9.884-9.884V142.076h30.886c5.458,0,9.884-4.425,9.884-9.883V34.345   C297,28.887,292.575,24.462,287.116,24.462z M277.233,122.309h-30.886c-5.458,0-9.883,4.425-9.883,9.883v120.579H60.537V132.192   c0-5.458-4.425-9.883-9.883-9.883H19.767v-78.08h70.757c4.712,27.738,28.916,48.923,57.976,48.923s53.264-21.185,57.976-48.923   h70.757V122.309z" />
            <path d="m152.363,108.848c-2.691,0.125-5.431,0.104-8.14-0.061-3.814-0.233-7.095,2.669-7.329,6.483-0.232,3.796 2.642,7.064 6.429,7.325 0.018,0.001 0.037,0.003 0.054,0.004 3.204,0.196 6.445,0.22 9.631,0.071 3.816-0.178 6.766-3.416 6.588-7.233-0.177-3.817-3.407-6.769-7.233-6.589z" />
            <path d="m186.662,98.602c-2.332,1.377-4.76,2.638-7.217,3.748-3.482,1.573-5.03,5.671-3.457,9.153 1.086,2.405 3.375,3.886 5.835,4.056 1.104,0.076 2.241-0.111 3.318-0.598 2.915-1.316 5.794-2.812 8.556-4.443 3.291-1.943 4.383-6.185 2.44-9.474-1.944-3.294-6.186-4.386-9.475-2.442z" />
            <path d="m117.206,101.942c-2.446-1.143-4.858-2.436-7.171-3.843-3.261-1.984-7.52-0.949-9.507,2.316-1.986,3.264-0.949,7.521 2.316,9.507 2.742,1.667 5.602,3.201 8.503,4.556 0.796,0.372 1.625,0.579 2.451,0.636 2.77,0.191 5.5-1.309 6.746-3.975 1.619-3.462 0.123-7.579-3.338-9.197z" />
            <path d="m84.775,72.685c-1.968-3.276-6.219-4.334-9.493-2.368-3.275,1.968-4.336,6.218-2.368,9.493 1.647,2.742 3.466,5.427 5.406,7.981 1.257,1.655 3.114,2.585 5.036,2.718 1.612,0.111 3.27-0.338 4.658-1.393 3.043-2.311 3.636-6.651 1.324-9.694-1.637-2.157-3.173-4.424-4.563-6.737z" />
            <path d="m208.809,89.922c1.129,0.883 2.448,1.36 3.778,1.451 2.215,0.153 4.463-0.761 5.934-2.642 1.974-2.525 3.83-5.187 5.517-7.912 2.012-3.248 1.009-7.513-2.24-9.523-3.247-2.014-7.512-1.01-9.523,2.24-1.424,2.298-2.99,4.544-4.655,6.675-2.355,3.009-1.822,7.357 1.189,9.711z" />
            <path d="m83.269,117.12c-3.821,0-6.919,3.097-6.919,6.919v4.448c0,3.821 3.097,6.918 6.919,6.918s6.918-3.097 6.918-6.918v-4.448c-1.42109e-14-3.822-3.097-6.919-6.918-6.919z" />
            <path d="m83.269,150.964c-3.821,0-6.919,3.097-6.919,6.918v8.534c0,3.821 3.097,6.918 6.919,6.918s6.918-3.097 6.918-6.918v-8.534c-1.42109e-14-3.821-3.097-6.918-6.918-6.918z" />
            <path d="m83.269,188.895c-3.821,0-6.919,3.097-6.919,6.918v8.535c0,3.821 3.097,6.918 6.919,6.918s6.918-3.097 6.918-6.918v-8.535c-1.42109e-14-3.821-3.097-6.918-6.918-6.918z" />
            <path d="m83.269,226.827c-3.821,0-6.919,3.097-6.919,6.918v4.448c0,3.821 3.097,6.919 6.919,6.919s6.918-3.097 6.918-6.919v-4.448c-1.42109e-14-3.821-3.097-6.918-6.918-6.918z" />
            <path d="m213.731,117.12c-3.821,0-6.919,3.097-6.919,6.919v4.448c0,3.821 3.097,6.918 6.919,6.918 3.821,0 6.919-3.097 6.919-6.918v-4.448c0-3.822-3.098-6.919-6.919-6.919z" />
            <path d="m213.731,188.895c-3.821,0-6.919,3.097-6.919,6.918v8.535c0,3.821 3.097,6.918 6.919,6.918 3.821,0 6.919-3.097 6.919-6.918v-8.535c0-3.821-3.098-6.918-6.919-6.918z" />
            <path d="m213.731,150.964c-3.821,0-6.919,3.097-6.919,6.918v8.534c0,3.821 3.097,6.918 6.919,6.918 3.821,0 6.919-3.097 6.919-6.918v-8.534c0-3.821-3.098-6.918-6.919-6.918z" />
            <path d="m213.731,226.827c-3.821,0-6.919,3.097-6.919,6.918v4.448c0,3.821 3.097,6.919 6.919,6.919 3.821,0 6.919-3.097 6.919-6.919v-4.448c0-3.821-3.098-6.918-6.919-6.918z" />
        </g>
    </svg>
    )
}

// ==============================|| ORDER TABLE ||============================== //
const Inventory = () => {
    const [rows, setRows] = useState([]);
    const [flag, setFlag] = useState(true);
    const [staticRows, setStaticRows] = useState([]);
    const cookies = new Cookies();
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const [rowSelectionModel, setRowSelectionModal] = useState([]);
    const inputRef = useRef(null);
    const [csvReport, setCsvReport] = useState({ data: [], headers: [], filename: 'Inventory.csv' });
    const currentYear = new Date().getFullYear().toString().slice(-2);
    const nextYear = (new Date().getFullYear() + 1).toString().slice(-2);

    // Filter Autocomplete Data
    const [returnFabricIdFilterData, setReturnFabricIdFilterData] = useState([]);
    const [rollIdFilterData, setRollIdFilterData] = useState([]);
    const [locationFilterData, setLocationFilterData] = useState([]);

    // Filters
    const [selectedReturnFabricId, setSelectedReturnFabricId] = useState(null);
    const [selectedRollId, setSelectedRollId] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);

    // preSignedUrl
    const { preSignedUrls, fetchPreSignedUrl } = useContext(PreSignUrlContext)
    // const [preSignedUrls,setPreSignedUrls]=useState({});

    //FAB ID	ROLL ID	Location 	Fab_Roll_ID	FabID_Key 	Inventory C/F	Production In	Production Out	Print in	Print Out	Shrinkage In 	Shrinkage Out	Dye In	Dye Out	GRN	Inventory
    const columns = [
        {
            id: 'returnFabricId',
            field: 'returnFabricId',
            align: 'left',
            disablePadding: false,
            headerName: 'Return Fabric Id',
            key: 'returnFabricId',
            label: 'Fabric Id',
            width: 200,
        },
        {
            id: 'rollId',
            field: 'rollId',
            align: 'left',
            disablePadding: false,
            headerName: 'Roll Id',
            key: 'rollId',
            label: 'Roll Id',
            width: 200,
        },
        {
            id: 'location',
            field: 'location',
            align: 'left',
            disablePadding: false,
            headerName: 'Location',
            key: 'location',
            label: 'location',
            width: 200,
        },
        {
            id: 'image',
            field: 'image',
            align: 'left',
            disablePadding: false,
            headerName: 'Image',
            key: 'image',
            label: 'image',
            renderCell: (params) => (params.value === 'sample' ? <SvgComponent />
                : <img src={preSignedUrls?.[params?.row?.returnFabricId]?.url} alt="fabric" />),
            width: 100
        },
        {
            id: 'color',
            field: 'color',
            align: 'left',
            disablePadding: false,
            headerName: 'Color',
            key: 'color',
            label: 'color',
            width: 200,
        },
        {
            id: 'closingStock',
            field: 'closingStock',
            align: 'left',
            disablePadding: false,
            headerName: 'Closing Stock',
            key: 'closingStock',
            label: 'closingStock',
            width: 200,
        },
        {
            id: 'productionIn',
            field: 'productionIn',
            align: 'left',
            disablePadding: false,
            headerName: 'production In',
            key: 'productionIn',
            label: 'productionIn',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'productionOut',
            field: 'productionOut',
            align: 'left',
            disablePadding: false,
            headerName: 'production Out',
            key: 'productionOut',
            label: 'productionOut',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'print_GRN',
            field: 'print_GRN',
            align: 'left',
            disablePadding: false,
            headerName: 'Print in',
            key: 'print_GRN',
            label: 'print in',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'print',
            field: 'print',
            align: 'left',
            disablePadding: false,
            headerName: 'Print Out',
            key: 'print',
            label: 'print',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'shrinkage_GRN',
            field: 'shrinkage_GRN',
            align: 'left',
            disablePadding: false,
            headerName: 'Shrinkage In',
            key: 'shrinkage_GRN',
            label: 'shrinkage in',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'shrinkage',
            field: 'shrinkage',
            align: 'left',
            disablePadding: false,
            headerName: 'Shrinkage Out',
            key: 'shrinkage',
            label: 'shrinkage',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'dye_GRN',
            field: 'dye_GRN',
            align: 'left',
            disablePadding: false,
            headerName: 'Dye In',
            key: 'dye_GRN',
            label: 'dye in',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'dye',
            field: 'dye',
            align: 'left',
            disablePadding: false,
            headerName: 'dye Out',
            key: 'dye',
            label: 'dye',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },
        {
            id: 'GRN',
            field: 'GRN',
            align: 'left',
            disablePadding: false,
            headerName: 'GRN',
            key: 'GRN',
            label: 'GRN',
            valueGetter: (params) => params.value == null ? 0 : params.value,
            width: 200,
        },

        {
            id: 'quantity',
            field: 'quantity',
            align: 'left',
            disablePadding: false,
            headerName: 'Inventory',
            key: 'quantity',
            label: 'quantity',
            width: 200,
        },
    ];

    const handleExportClick = () => {
        inputRef.current.click();
    }

    const handleFilterReset = async () => {
        setSelectedReturnFabricId(null);
        setSelectedRollId(null);
        setSelectedLocation(null);
        await fetchInventoryView();
    }

    const handleFilterSubmit = async () => {
        await fetchInventoryView({
            "returnFabricId": selectedReturnFabricId,
            "rollId": selectedRollId,
            "location": selectedLocation
        });
    }

    const fetchInventoryView = async (filters) => {
        setBuffer(true);
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/inventory/fabric/snapshot`;
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            redirect: 'follow',
            body: JSON.stringify({
                "filters": {
                    "returnFabricId": filters && filters.returnFabricId ? filters.returnFabricId : null,
                    "rollId": filters && filters.rollId ? filters.rollId : null,
                    "location": filters && filters.location ? filters.location : null
                }
            })
        };
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            let data = await res.json();
            data = data.map((item, index) => { return { ...item, "id": data[index].returnFabricId + data[index].rollId+data[index].location, "sno": index } })
            setCsvReport({
                data: data,
                headers: columns,
                filename: `Inventory-${moment(new Date()).format('DD-MM-YYYY')}.csv`
            });

            setRows(data);
            if (Object?.keys(preSignedUrls)?.length == 0)
                fetchPreSignedUrl(data.map((item) => item.returnFabricId));
            if (flag) {
                setFlag(false);
                setStaticRows(data);
                const filterReturnFabricIdDataSet = [... new Set(data.map(item => item.returnFabricId))];
                const filterRollIdDataSet = [... new Set(data.map(item => item.rollId))];
                const filterLocationDataSet = [... new Set(data.map(item => item.location))];
                setReturnFabricIdFilterData(filterReturnFabricIdDataSet);
                setRollIdFilterData(filterRollIdDataSet);
                setLocationFilterData(filterLocationDataSet);
            }
        }
        if (!res.ok) {
            setError('Error in displaying purchase order data');
        }
        setBuffer(false);
    }

    useEffect(() => {
        if (flag === true) {
            (async () => {
                await fetchInventoryView(
                    {
                        "returnFabricId": selectedReturnFabricId,
                        "rollId": selectedRollId,
                        "location": selectedLocation
                    }
                );
            })
                ()

        };

    }, [flag])

    useEffect(() => {
        let filterDataList = structuredClone(staticRows);
        if (selectedReturnFabricId) {
            filterDataList = staticRows.filter(obj => obj.returnFabricId == selectedReturnFabricId);
            const newFilterRollIdDataset = [...new Set(filterDataList.map(item => {
                return item.rollId;
            }))];
            setRollIdFilterData(newFilterRollIdDataset);
            const newLocationFilterDataset = [...new Set(filterDataList.map(item => {
                return item.location;
            }))];
            setLocationFilterData([...newLocationFilterDataset])
        }
        else {
            const newFilterRollIdDataset = [...new Set(staticRows.map(item => {
                return item.rollId;
            }))];
            setRollIdFilterData([...newFilterRollIdDataset])
            const newLocationFilterDataset = [...new Set(staticRows.map(item => {
                return item.location;
            }))];
            setLocationFilterData([...newLocationFilterDataset])
        }
        if (selectedRollId) {
            filterDataList = staticRows.filter(obj => obj.rollId == selectedRollId);
            const newFilterReturnFabricIdDataset = [...new Set(filterDataList.map(item => {
                return item.returnFabricId;
            }))];
            setReturnFabricIdFilterData([...newFilterReturnFabricIdDataset])
            const newLocationFilterDataset = [...new Set(filterDataList.map(item => {
                return item.location;
            }))];
            setLocationFilterData([...newLocationFilterDataset])
        }
        else {
            const newFilterReturnFabricIdDataset = [...new Set(staticRows.map(item => {
                return item.returnFabricId;
            }))];
            setReturnFabricIdFilterData([...newFilterReturnFabricIdDataset])
            const newLocationFilterDataset = [...new Set(staticRows.map(item => {
                return item.location;
            }))];
            setLocationFilterData(newLocationFilterDataset)
        }
        if (selectedLocation) {
            filterDataList = staticRows.filter(obj => obj.location == selectedLocation);
            const newFilterRollIdDataset = [...new Set(filterDataList.map(item => {
                return item.rollId;
            }))];
            setRollIdFilterData(newFilterRollIdDataset)
            const newReturnFabricIdFilterDataset = [...new Set(filterDataList.map(item => {
                return item.returnFabricId;
            }))];
            setReturnFabricIdFilterData(newReturnFabricIdFilterDataset);
        }
        else {
            const newFilterRollIdDataset = [...new Set(staticRows.map(item => {
                return item.rollId;
            }))];
            setRollIdFilterData(newFilterRollIdDataset)
            const newReturnFabricIdFilterDataset = [...new Set(staticRows.map(item => {
                return item.returnFabricId;
            }))];
            setReturnFabricIdFilterData(newReturnFabricIdFilterDataset);
        }
    }, [selectedReturnFabricId, selectedRollId, selectedLocation, staticRows]);



    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
            <MDBox>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, gap: "0.5rem" }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '0.6rem', paddingTop: '0.3rem', marginTop: 5, marginBottom: 5, alignItems: 'center', gap: '1rem', overflowX: 'auto', whiteSpace: "nowrap" }} >
                        <Autocomplete
                            options={returnFabricIdFilterData}
                            size='small'
                            getOptionLabel={(option) => option}
                            value={selectedReturnFabricId}
                            onChange={(event, newValue) => {
                                setSelectedReturnFabricId(newValue);
                            }}
                            renderInput={(params) =>
                            (<TextField
                                {...params}
                                label="Fabric Id"
                                variant="outlined"
                                placeholder='Fabric Id'
                                sx={{ minWidth: '9em' }}
                            />
                            )
                            }
                        />
                        <Autocomplete
                            options={rollIdFilterData}
                            size='small'
                            getOptionLabel={(option) => option}
                            value={selectedRollId}
                            onChange={(event, newValue) => {
                                setSelectedRollId(newValue);
                            }}
                            renderInput={(params) =>
                            (<TextField
                                {...params}
                                label="Roll Id"
                                variant="outlined"
                                placeholder='Roll Id'
                                sx={{ minWidth: '9em' }}
                            />
                            )
                            }
                        />
                        <Autocomplete
                            options={locationFilterData}
                            size='small'
                            getOptionLabel={(option) => option}
                            value={selectedLocation}
                            onChange={(event, newValue) => {
                                setSelectedLocation(newValue);
                            }}
                            renderInput={(params) =>
                            (<TextField
                                {...params}
                                label="Location"
                                variant="outlined"
                                placeholder='Location'
                                sx={{ minWidth: '9em' }}
                            />
                            )
                            }
                        />
                    </div> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MDButton
                            variant='outlined'
                            size='small'
                            style={{ textTransform: 'capitalize' }}
                            circular

                        >
                            <div
                                style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '1rem' }}
                            >
                                Total Inventory:{rows?.reduce((acc, item) => item?.quantity + acc, 0).toFixed(2)}
                            </div>

                        </MDButton>
                        <MDButton color="info" size="medium" variant="outlined" onClick={handleExportClick} style={{ marginRight: 5, }} ref={inputRef}>
                            <CSVLink {...csvReport} >Export</CSVLink>
                        </MDButton>
                    </Box>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', marginLeft: "auto", marginRight: 5, float: "right" }} >
                        <MDButton color="info" variant="outlined" onClick={handleFilterReset} circular="true" sx={{
                            "color": "#00008B",
                            "border": '1px solid #00008B',
                        }}>
                            Reset
                        </MDButton>
                        <MDButton variant="contained" onClick={handleFilterSubmit} circular="true" sx={{
                            "color": "white",
                            "border": '1px solid #00008B',
                            "background": "#00008B"
                        }}>
                            Apply
                        </MDButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                        <div style={{ height: "56.875rem", width: '100%' }}>
                            {buffer && <div style={{
                                textAlign: 'center',
                                padding: '20vh 0vh',
                                marginLeft: '50%'
                            }}>
                                <CircularProgress />
                            </div>}
                            {!buffer && (error == null) &&

                                <DataGrid
                                    sx={{ height: '100%' }}
                                    rows={rows}
                                    rowHeight={50}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 25 },
                                        },
                                    }}
                                    onRowSelectionModelChange={(newRowSelectionModel) => {
                                        setRowSelectionModal(newRowSelectionModel)
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    pageSizeOptions={[25, 50, 100]}

                                />

                            }
                            {!buffer && (error != null) && <Alert severity='error' dismissible='true'>{error}</Alert>}
                        </div>
                    </div>
                </div>
            </MDBox>
        </Paper >
    );
}
export default Inventory;