import { createContext, useState } from "react";

export const PreSignUrlContext = createContext();

export const PreSignUrlProvider = ({ children }) => {
    const [preSignedUrls, setPreSignedUrls] = useState({});
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const fetchPreSignedUrl = async (fabricIds) => {
        setBuffer(true);
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/fabricMaster/preSignedUrl`;
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            redirect: 'follow',
            body: JSON.stringify({
                "fabricIds": fabricIds
            })
        };
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            let data = await res.json();
            setPreSignedUrls(data);
        }
        if (!res.ok) {
            setError('Error in displaying purchase order data');
        }
        setBuffer(false);
    }
    return (
        <PreSignUrlContext.Provider value={{ preSignedUrls, fetchPreSignedUrl }}>
            {children}
        </PreSignUrlContext.Provider>
    );
}