import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell
} from '@mui/x-data-grid';
import Cookies from 'universal-cookie';
import fabricVendorMappingSchema from './fabricVendorMappingUtil'
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Modal,
  Snackbar,
  Button,
  TextField,
  Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import MDButton from '../../../../components/MDButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
let cookies = new Cookies();

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));


const addRow = (setAddNewRow, selectedVendor, id = null) => {
  setAddNewRow(preRows => [...preRows, { ...fabricVendorMappingSchema, vendorMasterId: selectedVendor?.vendorMasterId, id: id, createdBy: cookies.get("id") }])
}

const validate = (internalIds, newRows) => {
  const internalIdsCol = [...internalIds];
  newRows.forEach(element => {
    if (element?.internalId != "" && element?.internalId !== null)
      internalIdsCol.push(element.internalId)
  })
  const vendorUniqueSet = new Set(internalIdsCol);
  return vendorUniqueSet.size !== internalIdsCol?.length ? true : false;
}
const AddNewFabricToVendorMapping = ({ addNewRow, setAddNewRow, setSnackBar, selectedVendor, setNewFabricVendorMappingAdded }) => {
  const [error, setError] = useState('');
  const [isDuplicate, setIsDuplicate] = useState(0);
  const [rows, setRows] = useState([]);
  const [snackBarError, setSnackBarError] = useState('');
  const addVendorFabricMapping = async (addNewRow) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/vendorMaster/fabricToVendorMapping`;
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      redirect: 'follow',
      body: JSON.stringify({
        data: addNewRow
      })
    };
    const res = await fetch(url, requestOptions);

    if (res.ok) {
      await res.json();
      setSnackBar(`new fabric added to vendor "${selectedVendor?.vendorName}" successfully`);
      setAddNewRow([{ ...fabricVendorMappingSchema, vendorMasterId: selectedVendor?.vendorMasterId, createdBy: cookies.get("id") }]);
      setNewFabricVendorMappingAdded(1);
      handleAddNewFabricSubmit();
    }
    if (!res.ok) {
      setSnackBarError('Error in adding new vendors');
    }
  }

  const addNewVendors = async () => {
    let escapeApiCall = 0;
    let fabricIdSet = new Set(addNewRow.map((row) => row.fabricMasterId));
    addNewRow?.some((elObj, index) => {
      if (elObj?.fabric_id == "" || elObj?.fabric_id == undefined) {
        setSnackBarError(`FabricId cannot be empty for  row no.${index + 1}`)
        escapeApiCall = 1;
        return true;
      }
      else if (fabricIdSet?.size !== addNewRow?.length) {
        setSnackBarError("duplicate fabricId ")
        escapeApiCall = 1;
        return true;
      }
      else if (elObj?.rate == "" || elObj?.rate == undefined) {
        setSnackBarError(`rate cannot be empty for ${elObj?.fabric_id} at row no ${index + 1}`)
        escapeApiCall = 1;
        return true;
      }
      else if (elObj?.moq == "" || elObj?.moq == undefined) {
        setSnackBarError(`moq cannot be empty for ${elObj?.fabric_id} at row no ${index + 1}`)
        escapeApiCall = 1;
        return true;
      }


    });
    // call the api
    if (!escapeApiCall)
      await addVendorFabricMapping(addNewRow?.map((row) => {
        return {
          vendorMasterId: row?.vendorMasterId,
          fabricMasterId: row?.fabricMasterId,
          rate: row?.rate,
          moq: row?.moq,
          createdBy: row?.createdBy
        }
      }))
  }

  const fetchFabrics = async () => {
    try {
      // setBuffer(true);
      let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/fabricMaster/details/allFabric`;
      const requestOptions = {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
        redirect: 'follow',
      };
      const res = await fetch(url, requestOptions);

      if (res.ok) {
        let data = await res.json();
        // console.log(data, 'test');
        setRows(data?.map((item) => { return { ...item, vendorMasterid: selectedVendor.vendorMasterId } }));

      }
      if (!res.ok) {
        setError('Error in displaying fabricDetails data');
      }
      // setBuffer(false);
    }
    catch (error) {
      setError('Error fetching  fabricDetails data')
      console.error(error);
    }
  }
  let promiseTimeout;
  // function validateName(username) {
  //   validate(internalIds, [{ internalId: username }, ...addNewRow]);
  //   const isUnique = validate(internalIds, [{ internalId: username }, ...addNewRow]);
  //   setIsDuplicate(isUnique);
  //   return new Promise((resolve) => {
  //     promiseTimeout = setTimeout(
  //       () => {
  //         resolve(isUnique ?
  //           'duplicate vendor id' : '');
  //       },
  //       Math.random() * 100 + 100,
  //     );
  //   });
  // }

  function NameEditInputCell(props) {
    const { error } = props;

    return (
      <StyledTooltip open={!!error} title={error}>
        <GridEditInputCell {...props} />
      </StyledTooltip>
    );
  }

  const handleColumnChange = (id, column, value) => {

    const fabric = rows?.filter((item) => item?.[column] == value)
    // console.log(fabric[0]?.id,'fabric')
    setAddNewRow((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, [column]: value, ['fabricMasterId']: fabric?.[0]?.id } : row
      )
    );
  };
  //VALIDATION PENDING
  const newVendorColumn = [
    {
      id: 'fabric_id',
      field: 'fabric_id',
      align: 'left',
      disablePadding: false,
      // editable: true,
      headerName: 'Fabric Id',
      key: 'fabric_id',
      label: 'Fabric Id',
      width: 300,
      renderCell: (params) => (
        <Autocomplete
          options={rows?.map((item) => item.fabric_id)}
          value={params.row.fabric_id || ''}
          onChange={(event, newValue) =>
            handleColumnChange(params.row.id, 'fabric_id', newValue)
          }
          renderInput={(params) => <TextField {...params} sx={{ minWidth: '14rem', width: '100%' }} />}
        />
      ),
    },
    {
      id: 'vendorMasterId',
      field: 'vendorMasterId',
      align: 'left',
      disablePadding: false,
      headerName: 'vendor Name',
      key: 'vendorMasterId',
      label: 'vendor',
      renderCell: (params) => (
        selectedVendor?.vendorName
      ),
      width: 300,
    },
    {
      id: 'rate',
      field: 'rate',
      align: 'left',
      disablePadding: false,
      editable: true,
      headerName: 'rate',
      label: 'rate',
      width: 200,
    },
    {
      field: 'moq',
      type: 'moq',
      headerName: 'Moq',
      editable: true,
      width: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const id = params?.id;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={isDuplicate}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"

            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];


  const [openModal, setOpenModal] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleClose = () => {
    setOpenModal(false);
  }
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setAddNewRow(addNewRow.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = addNewRow.find((row) => row.id === id);
    if (editedRow.isNew) {
      const newRows = addNewRow.filter((row) => row.id !== id);
      setAddNewRow(newRows);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    // console.log('helo',updatedRow)
    const newRows = addNewRow.map((row) => (row.id === newRow.id ? updatedRow : row))
    // validate(internalIds, newRows, setIsDuplicate);
    setAddNewRow(newRows);
    return updatedRow;
  };
  function EditToolbar(props) {
    const { setAddNewRow, addNewRow, setRowModesModel } = props;
    const handleClick = () => {
      const id = Date.now();
      addRow(setAddNewRow, selectedVendor, id);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Save, fieldToFocus: 'fabricId' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" disabled={isDuplicate} startIcon={<AddIcon />} onClick={handleClick}>
          Add
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleAddNewFabricSubmit = () => {
    setOpenModal(false);
    setError('');
    setSnackBarError('');
  }
  // console.log(selectedVendor, 'sssvvv')
  useEffect(() => {
    fetchFabrics();
  }, [])
  return (
    <Box>
      <MDButton
        variant='outlined'
        size='small'
        style={{ textTransform: 'capitalize' }}
        circular
        onClick={() => setOpenModal(true)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '1.5rem',
              margin: '0.1rem',
              marginRight: '.6rem'
            }}
          >
            +
          </div>
          <div
            style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '1rem' }}
          >
            Add New Fabric Vendor Mapping
          </div>
        </div>
      </MDButton>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Snackbar
            open={snackBarError?.length}
            autoHideDuration={3000}
            onClose={() => {
              setSnackBarError(false)
            }}
            message={snackBarError}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
          <Box
            sx={{
              height: 500,
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <StyledBox>
              <DataGrid
                rows={addNewRow}
                columns={newVendorColumn}
                // editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: { setAddNewRow, addNewRow, setRowModesModel },
                }}
              />
            </StyledBox>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <MDButton variant='outlined' sx={{ marginTop: '0.4rem' }} circular size="medium"
              disabled={error?.length || isDuplicate}
              onClick={() => addNewVendors()}
            >
              Submit
            </MDButton>
          </Box>
        </Box>
      </Modal >
    </Box>
  );
}

export default AddNewFabricToVendorMapping;